
//Home screen for web

import * as React from 'react';
import { View, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import FilterDropDown from '../../Components/WebComponent/FilterDropDown.web'
import StocksListWeb from '../../Components/WebComponent/StocksListWeb'
import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import { connect } from 'react-redux';
import Loader from '../../Components/common/LoaderComponent/Loader';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
const windowHeight = Dimensions.get('window').height;


class HomeScreen extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (

      <View key={'headerContainer'} style={styles.headerContainer}>
        <HeaderWeb pageTitleArray={[this.props.selectedSection]} />
        <View key={'mainContainer'} style={styles.mainContainer}>

          <View style={{ flexDirection: 'row' }}>

            <View style={{ flex: 1, }}>
              <View key={'shadowContainer'} style={styles.shadowContainer}>
                <View key={'titleView'} style={styles.titleView}>
                  <Text key={'titleText'} style={styles.titleText}>{this.props.selectedSection}</Text>
                  {(this.props.selectedSubSection.label != 'Hide' && this.props.selectedSubSection.label != 'All') &&
                    <View style={{ height: (30), justifyContent: 'center', paddingHorizontal: 12, marginTop: 3 }}>
                      <Text style={[styles.titleText, { fontSize: 14 }]}>Showing stocks for {this.props.selectedSubSection.label}</Text>
                    </View>
                  }
                </View>
                <View key={'separatorView'} style={styles.separatorView} />

                <View key={'serchAndFilterView'} style={styles.serchAndFilterView}>
                  <FilterDropDown selectedSection={this.props.selectedSection} />
                </View>

                <View key={'tableView'} style={{ marginTop: 20 }}>
                  <StocksListWeb props={this.props.props} />
                </View>
              </View>
            </View>

            <View style={{ width: '20%', paddingHorizontal: 5 }}>

              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="billgunderson"
                options={{ height: windowHeight - 160 }}
              />

            </View>

          </View>

          {Platform.OS == 'web' && this.props.isLoadingShowing && <Loader />}
        </View>
      </View>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedSection: state.stocksReducers.selectedSection,
    selectedSubSection: state.stocksReducers.selectedSubSection,
    isLoadingShowing: state.stocksReducers.isLoadingShowing,
  }
}

export default connect(mapStateToProps, null)(HomeScreen);


const styles = StyleSheet.create({

  headerContainer: {
    flex: 1,
    backgroundColor: '#f2f4f8',
  },

  mainContainer: {
    flex: 1,
    backgroundColor: '#f2f4f8',
    padding: 20,
    paddingTop: 30,
  },

  shadowContainer: {
    // flex: 1,
    backgroundColor: 'white',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    borderRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
  },

  titleView: {
    height: 60,
    paddingStart: 20,
    paddingEnd: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'


  },

  titleText: {
    fontSize: 20,
    fontFamily: Constant.AppFonts.Roboto.Bold,
    fontWeight: Constant.AppFontWeight.BOLD,
    // textTransform: 'capitalize',
    color: Colors.MAIN_TITLE_COLOR

  },

  separatorView: {
    height: 2,
    backgroundColor: Colors.APP_COLOR_BORDER
  },

  serchAndFilterView: {
    height: 45,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15

  }
});

