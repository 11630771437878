//initial app.js file which will initiate the custom class(Component) Mainroot


import React from 'react';
import MainRoot from './Navigation/MainRoot';

export default class App extends React.Component {
    render() {
        return (
            <MainRoot />
        );
    }
}
