
import * as React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import FavoriteListPopup from '../../Components/WebComponent/StockDetails/FavoriteListPopup.web';
import StockDetailsSection from '../../Components/WebComponent/StockDetails/StockDetailsSection.web';
import MyStorage from '../../../Services/MyStorage';

class StockDetailsContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (

      <View key={'headerContainer'} style={styles.headerContainer}>
        <HeaderWeb props={this.props} pageTitleArray={[this.props.route.params.sector]} />
        <ScrollView key={'mainContainer'} style={styles.mainContainer}>
          <View style={{ alignItems: 'center', flex: 1, }}>
            <View style={{ width: '85%' }}>
              <View key={'titleView'} style={{ height: 50, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', }}>
                <Text key={'titleText'} style={styles.titleText}>{this.props.route.params.symbol}</Text>
                {parseInt(MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)) == 1 &&
                  <FavoriteListPopup symbol={this.props.route.params.symbol} />
                }
              </View>
              <StockDetailsSection symbol={this.props.route.params.symbol} />
            </View>
          </View>
        </ScrollView>
      </View>

    );
  }
}

export default StockDetailsContainer;


const styles = StyleSheet.create({

  headerContainer: {
    flex: 1,
    backgroundColor: Colors.APP_COLOR_BACKGROUND,
  },

  mainContainer: {
    flex: 1,
    backgroundColor: Colors.APP_COLOR_BACKGROUND,
    padding: 30,
    paddingTop: 30,
  },

  titleText: {
    fontSize: 20,
    fontFamily: Constant.AppFonts.Roboto.Bold,
    fontWeight: Constant.AppFontWeight.BOLD,
    color: Colors.MAIN_TITLE_COLOR
  },

});
