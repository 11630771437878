import * as React from 'react';
import { View, Text, StyleSheet, Image, TextInput, TouchableOpacity, ImageBackground } from 'react-native'
import Constant from '../../../../Utils/Constant';
import { showFlashBanner } from '../../../../Utils/FlashMessage';
import MyStorage from '../../../../Services/MyStorage';
import * as Colors from '../../../../Utils/Colors';
import Loader from '../../common/LoaderComponent/Loader.web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import HeaderWeb from '../../../Components/WebComponent/HeaderWeb';
import { checkValidation } from '../../../../Services/AppManager';

import ApiManager from '../../../../Services/Networking/ApiManager';
let apiManager = ApiManager.getInstance()


class ChangeEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isLoader: false
        }
    }

    onChangeEmail = () => {

        let emailStatus = checkValidation('email', this.state.email, 'Email')
        if (!emailStatus.status) {
            showFlashBanner(emailStatus.messsage, '1')
            return;
        }

        this.setState({
            isLoader: true
        })

        let dict = {
            userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
            email: this.state.email,
        }
        apiManager.onPostApi(Constant.EndPoint.changeEmail, dict).then((responce) => {

            this.setState({
                isLoader: false
            })
            if (responce.json.status == 200) {
                showFlashBanner(responce.json.message)
                this.props.navigation.goBack()
            } else {
                showFlashBanner(responce.json.message, '1')
            }

        }).catch((error) => {
            console.log(error.msg);
            this.setState({
                isLoader: false
            })
        })

    }



    render() {

        return (

            <View style={{ flex: 1 }}>
                <HeaderWeb props={this.props} pageTitleArray={[Constant.AppText.ManageSubscription, 'Change Email']} />

                <ImageBackground key={'mainContainer'}
                    style={styles.mainContainer}
                    source={require('../../../../../assets/web/authBg.png')}

                >
                    <View key={'shadowContainer'} style={styles.shadowContainer}>

                        <View style={{ flex: 1, justifyContent: 'center', }}>

                            <View key={'header'} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                <Text style={styles.header}>Change Email</Text>
                                <Text style={styles.subHeader}>{Constant.AppText.changeEmail}</Text>
                            </View>

                            <View key={'bodyView'} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                <View key={'emailContainer'} style={styles.emailContainer}>
                                    <MaterialCommunityIcons name="email-outline" size={20} color="#2996FF" />
                                    <TextInput
                                        type='Email'
                                        name='Email'
                                        placeholder={'Email Address'}
                                        placeholderTextColor="#A5A5A5"
                                        style={[styles.emailInput, { outline: 'none' }]}
                                        onChangeText={(text) => this.setState({ email: text })}
                                    />
                                </View>


                                <TouchableOpacity
                                    style={styles.buttonContainer}
                                    onPress={() => this.onChangeEmail()}
                                >
                                    <Text style={styles.buttonText}>SUBMIT</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={{ flex: 1, }}>
                            <Image key={'sideImage'}
                                style={{ flex: 1, borderBottomRightRadius: 10, borderTopRightRadius: 10 }}
                                source={require('../../../../../assets/web/sideViewImage/NoPath.png')}
                            />
                        </View>

                    </View>
                </ImageBackground>
                {this.state.isLoader && <Loader />}
            </View>
        )

    }
}

export default ChangeEmail;

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.APP_COLOR_BACKGROUND,

    },

    shadowContainer: {
        backgroundColor: "white",
        borderRadius: 10,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 2,
        width: '60%',
        height: '80%',
        flexDirection: 'row'
    },

    header: {
        fontSize: 25,
        color: Colors.MAIN_TITLE_COLOR,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    subHeader: {
        paddingTop: 10,
        fontSize: 14,
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL,
        lineHeight: 25
    },


    emailContainer: {
        marginTop: 30,
        borderWidth: 2,
        borderColor: Colors.APP_COLOR_BORDER,
        borderRadius: 5,
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderColor: 'rgba(0,0,0,0.1)',
        alignItems: 'center'
    },

    emailInput: {
        flex: 1,
        paddingHorizontal: 10,
        paddingVertical: 5,
        fontSize: 14,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        color: Colors.MAIN_TITLE_COLOR
    },

    buttonContainer: {
        marginTop: 50,
        backgroundColor: '#007AFF',
        borderRadius: 10,
        paddingVertical: 15,
        paddingHorizontal: 12,
        width: '100%',
    },

    buttonText: {
        fontSize: 16,
        color: '#fff',
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.BOLD,
        alignSelf: 'center',
        textTransform: 'uppercase',

    },


})

