
import * as React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import SubscriptionManager from '../../Components/WebComponent/AccountManager/SubscriptionManager';
import SubscriptionView from '../../Components/WebComponent/AccountManager/SubscriptionView';
import MyStorage from '../../../Services/MyStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';


class AccountContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      idRefresh: 0,
    }
  }

  async componentDidMount() {


    try {
      const userid = await AsyncStorage.getItem(Constant.ShareInstanceKey.kUserID)
      let userStr = JSON.parse(userid);
      if (userStr !== undefined && userStr !== null && userStr !== '') {
        MyStorage.setItem(Constant.ShareInstanceKey.kUserID, userStr)
      }
    } catch (e) {
      console.log('eror', e);
    }
    this.setState({ idRefresh: this.state.idRefresh + 1 })
  }


  realodPage = () => {

    this.setState({ idRefresh: this.state.idRefresh + 1 })
  }


  renderBody = () => {


    let userID = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
    console.log('refrsh', userID);
    if (userID != '') {
      return (
        <SubscriptionView props={this.props} />
      )
    }
    return (
      <SubscriptionManager props={this.props} onRefresh={this.realodPage.bind()} />
    )

  }

  render() {

    return (

      <View key={'headerContainer'} style={styles.headerContainer}>
        <HeaderWeb props={this.props} pageTitleArray={[Constant.AppText.ManageSubscription]} />
        <ScrollView key={'mainContainer'} style={styles.mainContainer}>
          {this.renderBody()}
        </ScrollView>
      </View>

    );
  }
}

export default AccountContainer;


const styles = StyleSheet.create({

  headerContainer: {
    flex: 1,
    backgroundColor: Colors.APP_COLOR_BACKGROUND,
  },

  mainContainer: {
    flex: 1,
    backgroundColor: Colors.APP_COLOR_BACKGROUND,
    padding: 30,
    paddingTop: 30,
  },

});
