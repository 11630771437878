
import React from 'react'
import { StyleSheet, Text, View, Platform, ScrollView, Linking } from 'react-native'
import * as Colors from '../../../../Utils/Colors';
import Constant from '../../../../Utils/Constant';
import { fontSizeScale } from '../../../../Services/AppManager';
import Loader from '../../common/LoaderComponent/Loader';


const IOS_URL = 'https://support.apple.com/en-us/HT202039';
const AND_URL = 'https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid#zippy=%2Cget-a-refund-on-subscriptions'
const PayPal_URL = 'https://www.paypal.com/va/smarthelp/article/how-do-i-cancel-a-recurring-payment,-subscription,-or-automatic-billing-agreement-i-have-with-a-merchant-faq1067';



class SubscriptionHelp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
        }
    }

    openWebUrl = (url) => {

        if (Platform.OS == 'web') {
            window.open(url, '_blank');

        } else {
            Linking.canOpenURL(url).then((supported) => {
                console.log('Url Support', supported);
                if (supported) {
                    return Linking.openURL(url);
                }
            });
        }
    }


    render() {


        return (
            <ScrollView style={{ backgroundColor: Colors.APP_COLOR_BACKGROUND }}>
                <View style={styles.container}>

                    <View key={'shadowContainer'} style={styles.shadowContainer}>

                        <Text style={styles.subscriptionText}>{Constant.AppText.SubscriptionsWhichAutomaticallyRenews}</Text>

                        <View style={{ marginTop: 20 }}>

                            <Text style={styles.subscriptionText}>{Constant.AppText.CancellationBillingText}</Text>

                        </View>
                        {/* <View style={styles.seperator} /> */}
                        <View style={{ marginTop: 20 }}>
                            <Text style={styles.subscriptionText}>{Constant.AppText.SubscriptionPurchasedFrom} iOS <Text onPress={() => this.openWebUrl(IOS_URL)} style={styles.restoreClickabletext}>Click here!</Text></Text>

                        </View>
                        <View style={{ marginTop: 20 }}>

                            <Text style={styles.subscriptionText}>{Constant.AppText.SubscriptionPurchasedFrom} Android <Text onPress={() => this.openWebUrl(AND_URL)} style={styles.restoreClickabletext}>Click here!</Text></Text>
                        </View>
                        <View style={{ marginTop: 20 }}>

                            <Text style={styles.subscriptionText}>{Constant.AppText.SubscriptionPurchasedFrom} PayPal <Text onPress={() => this.openWebUrl(PayPal_URL)} style={styles.restoreClickabletext}>Click here!</Text></Text>
                        </View>

                    </View>

                </View>
                <View style={{ height: 40 }} />
                {this.state.isLoader && <Loader message={'Loading...'} />}

            </ScrollView>
        )
    }
}


export default SubscriptionHelp;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
        alignItems: 'center'
    },

    shadowContainer: {
        marginTop: fontSizeScale(35),
        backgroundColor: 'white',
        padding: fontSizeScale(15),
        borderRadius: fontSizeScale(10),
        width: '90%',
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: fontSizeScale(3) },
        shadowOpacity: fontSizeScale(3),
        shadowRadius: fontSizeScale(5),
        elevation: fontSizeScale(3),
    },



    subscriptionText: {
        fontSize: fontSizeScale(13),
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        marginTop: fontSizeScale(5),
        lineHeight: fontSizeScale(18)
    },



    seperator: {
        borderBottomColor: Colors.APP_COLOR_BORDER,
        borderBottomWidth: fontSizeScale(2),
        marginLeft: fontSizeScale(-15),
        marginRight: fontSizeScale(-15),
        marginTop: fontSizeScale(15)
    },

    restoreClickabletext: {
        fontSize: 14,
        fontFamily: Constant.AppFonts.Roboto.MediumItalic,
        color: Colors.APP_DARK_GARY,
        // textDecorationLine: 'underline',
        color: '#007AFF'
    },


})

