import React from "react";
// import { _retrieveData, _header,_storeData } from '../Components/SharedPreference'
import Constant from '../../Utils/Constant';
import { Alert, Linking, Platform } from 'react-native';
import NetInfo from "@react-native-community/netinfo";
import { parse } from 'fast-xml-parser';
import { showFlashBanner } from '../../Utils/FlashMessage';
import MyStorage from '../MyStorage';

var authorization = '';
var sessionToken = '';
var authToken = '';
var authTokenLocal = '';

const WSErrorType = {
  SERVER: 1,
  API: 2,
  NETWORK: 3,
};

const ErrorMsg = {
  msgNoInternet: "Internet is not connected",
  msgConnectionLost: "Connection lost. Please check your network connectivity",
  msgServerError: "Something went wrong, please try again",
  msgRequestTimeOut: "Request time out, please try again",
}

export default class ApiManager extends React.Component {

  static myInstance = null;
  static getInstance() {

    if (this.myInstance === null) {
      this.myInstance = new ApiManager();
    }
    return this.myInstance;
  }

  getHeader() {

    return {

      'Content-Type': 'application/xml',
      'cache-control': 'no-cache',
      'Authorization': MyStorage.getItem(Constant.ShareInstanceKey.kAuthToken),
      'device-token': MyStorage.getItem(Constant.ShareInstanceKey.kDeviceToken),
      'Access-Control-Allow-Origin': '*',

    }
  }

  queryString = (obj) => {

    if (Object.keys(obj).length === 0) {
      return ''
    }

    let str = '?' + Object.keys(obj).reduce(function (a, k) {
      a.push(k + '=' + encodeURIComponent(obj[k]));
      return a;
    }, []).join('&');
    return str;
  }

  getMultiPartHeader() {
    return {

      'Content-Type': 'application/x-www-form-urlencoded',
      'cache-control': 'no-cache',
      'Authorization': MyStorage.getItem(Constant.ShareInstanceKey.kAuthToken),
      'device-token': MyStorage.getItem(Constant.ShareInstanceKey.kDeviceToken)
    }
  }

  onGetApi = async (endPoint, request = {}) => new Promise((resolve, reject) => {

    NetInfo.fetch().then(state => {
      if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

        let queryString = this.queryString(request)

        var baseUrl = Constant.API.BASE_URL
        if (Platform.OS === 'web') {
          baseUrl = Constant.API.WEB_BASE_URL
        }
        let apiUrl = baseUrl + endPoint + queryString
        // console.log('apiUrl AAya', apiUrl);

        let header = this.getHeader()

        // console.log('Header', header);


        fetch(apiUrl, {
          method: 'GET',
          headers: header
        }).then((response) => {

          response.json().then((json) => {

            // console.log('Response json ------- ', json);
            if (json.errors) {
              // console.log('ERRORs:----', json.errors);

              return reject(new WSError(ErrorMsg.msgServerError, json.errors.code, WSErrorType.API));
            } else {
              return resolve(new WSResult(response, json, 200))
            }

          }).catch((error) => {
            // console.log('ERROR:----', error);
            return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
          });

        }).catch((error) => {
          // console.log('ERROR:----', error);
          return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.SERVER));
        });

      } else {
        showFlashBanner(ErrorMsg.msgNoInternet, '1')
        return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API));

      }
    });
  });




  onPostApi = async (endPoint, requestData = {}, isMultipart = false) => new Promise((resolve, reject) => {

    NetInfo.fetch().then(state => {
      if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

        var baseUrl = Constant.API.BASE_URL
        if (Platform.OS === 'web') {
          baseUrl = Constant.API.WEB_BASE_URL
        }

        let newHeader = this.getMultiPartHeader()
        let jsonData = this.queryString(requestData).slice(1)


        // console.log('jsonData', jsonData);

        // console.log('HEADER:----', newHeader);

        // console.log('requestData:----', requestData);
        // console.log('URL :----', baseUrl + endPoint);

        fetch(baseUrl + endPoint, {
          method: 'POST',
          headers: newHeader,
          body: jsonData,

        }).then((response) => {

          // response.text().then((text) => {
          //   console.log(text);

          // })


          response.json().then((json) => {

            // console.log('API End Point:----', endPoint);
            // console.log('Response Data ------- ', response);
            // console.log('Response json ------- ', json);

            if (json.errors) {
              // console.log('ERRORs:----', json.errors);

              return reject(new WSError(ErrorMsg.msgServerError, json.errors.code, WSErrorType.API));
            } else {
              return resolve(new WSResult(response, json, 200))
            }

          }).catch((error) => {
            // console.log('ERROR:----', error);
            return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
          });

        }).catch((error) => {
          // console.log('ERROR:----', error);
          return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
        });
      } else {

        showFlashBanner(ErrorMsg.msgNoInternet, '1')
        return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API));
      }
    });
  });

  onGetSoapApi = async (endPoint) => new Promise((resolve, reject) => {

    NetInfo.fetch().then(state => {
      if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

        // console.log('----------Header---------\n', Constant.API.BASE_URL + endPoint);

        var baseUrl = Constant.API.BASE_URL
        if (Platform.OS === 'web') {
          baseUrl = Constant.API.WEB_BASE_URL
        }

        fetch(baseUrl + endPoint, {
          method: 'GET',
          headers: this.getHeader()
        }).then((response) => {

          response.text().then((textResponse) => {

            if (textResponse.errors) {
              // console.log('ERRORs:----', textResponse.errors);

              return reject(new WSError(ErrorMsg.msgServerError, textResponse.errors.code, WSErrorType.API));
            } else {
              return resolve(new WSResult(response, parse(textResponse), 200))

            }


          }).catch((error) => {
            console.log('ERROR:----', error);
            return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
          });

        }).catch((error) => {
          console.log('ERROR:----', error);
          return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API));
        });
      } else {
        return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API));
      }
    });
  });

}


class WSError {

  constructor(
    msg = '',
    code = 0,
    type = WSErrorType.API
  ) {
    this.msg = msg
    this.code = code
    this.type = type
  }
}

class WSResult {

  constructor(
    responce = Response,
    json = JSON,
    code = 0,
  ) {
    this.responce = responce
    this.json = json
    this.code = code
  }

}

