
import * as React from 'react';
import { View, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import PickerModal from 'react-native-picker-modal-view';
import { connect } from 'react-redux';
import { saveSubSection, addStockData, isApiLoader } from '../../../Services/Redux/actions/stocksAction';
import { fontSizeScale, getFilterOption, filterArrayByOptions } from '../../../Services/AppManager';
import DropDownPicker from 'react-native-dropdown-picker';
import Constant from '../../../Utils/Constant';
import Ionicons from 'react-native-vector-icons/Ionicons';
import * as Colors from '../../../Utils/Colors';
import MyStorage from '../../../Services/MyStorage';

class FilterList extends React.Component {

    constructor(props) {
        super(props);
        this.controller;
    }

    onSelected = (selected) => {

        MyStorage.setItem(Constant.ShareInstanceKey.kSelectedAlphabetKey, '')
        let arrStocksData = this.props.arrAllStockData;
        let filteredData = filterArrayByOptions(arrStocksData, selected)
        this.props.saveNewSubSection(selected)
        this.props.addStockData(filteredData)

    }

    onBackButtonPressed() {
        console.log('back key pressed');
    }

    renderMobileView = () => {
        return (
            <View style={{ alignItems: 'center', height: fontSizeScale(40), justifyContent: 'center' }}>
                <PickerModal
                    renderSelectView={(disabled, selected, showModal) =>

                        <View>
                            <TouchableOpacity onPress={showModal}>
                                <Ionicons name={'funnel-sharp'} size={25} color={Colors.APP_COLOR1} />

                            </TouchableOpacity>

                        </View>
                    }
                    onSelected={this.onSelected.bind(this)}
                    onBackButtonPressed={this.onBackButtonPressed.bind(this)}
                    items={getFilterOption(this.props.selectedSection)}
                    showToTopButton={false}
                    // selected={this.props.selectedSubSection}
                    showAlphabeticalIndex={false}
                    selectPlaceholderText={'Choose one...'}
                    onEndReached={() => console.log('list ended...')}
                    searchPlaceholderText={'Search...'}
                    requireSelection={true}
                    sortingLanguage='none'
                />

            </View>
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    height: 1,
                    width: "86%",
                    backgroundColor: "#CED0CE",
                }}
            />
        );
    };


    renderWebSiteView = () => {

        return (

            <View style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 2, paddingTop: 2, borderRadius: fontSizeScale(25), borderWidth: 1, borderColor: '#c1c1c1', minWidth: 200 }}>
                <DropDownPicker

                    controller={instance => this.controller = instance}
                    zIndex={5000}
                    items={getFilterOption(this.props.selectedSection)}
                    defaultIndex={0}
                    style={{ backgroundColor: 'white', borderWidth: 0 }}
                    onChangeItem={item => this.onSelected(item)}
                    defaultValue={this.props.arrAllStockData.length > 0 ? this.props.selectedSubSection.Name : ''}
                    labelStyle={{ fontSize: fontSizeScale(14), color: '#000', fontFamily: Constant.AppFonts.Roboto.Regular }}
                    containerStyle={{ height: fontSizeScale(35), }}
                    arrowStyle={{ marginRight: fontSizeScale(10), marginTop: fontSizeScale(3), marginLeft: fontSizeScale(6) }}
                    dropDownStyle={{
                    }}
                    dropDownMaxHeight={500}
                    renderSeperator={this.renderSeparator}
                    itemStyle={{
                        justifyContent: 'flex-start',

                    }}
                />
            </View>
        )
    }


    render() {
        return (
            <View>

                {Platform.OS != 'web' && this.renderMobileView()}
                {Platform.OS == 'web' && this.renderWebSiteView()}
            </View>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedSection: state.stocksReducers.selectedSection,
        selectedSubSection: state.stocksReducers.selectedSubSection,
        arrAllStockData: state.stocksReducers.stocksModelData,
        stockData: state.stocksReducers.stockData,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveNewSubSection: (subName) => dispatch(saveSubSection(subName)),
        addStockData: (filterData) => dispatch(addStockData(filterData)),
        isApiLoader: (loading) => dispatch(isApiLoader(loading))


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterList);