import * as React from 'react';
import { SearchBar } from 'react-native-elements';
import { View, Text, StyleSheet, Image, Platform } from 'react-native'
import { connect } from 'react-redux';
import { setSearchedKey, addStockData } from '../../../Services/Redux/actions/stocksAction';
import { filterArrayByOptions, fontSizeScale } from '../../../Services/AppManager';
import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import MyStorage from '../../../Services/MyStorage';

class SearchWeb extends React.Component {

  constructor(props) {
    super(props);

    this.timeout = 0;
    this.state = {
      searchText: '',
      selectedSubSection: ''
    }
  }

  componentDidMount() {

  }

  componentDidUpdate() {

    if (this.state.selectedSubSection != this.props.selectedSubSection) {
      this.setState({
        searchText: '',
        selectedSubSection: this.props.selectedSubSection
      })
      this.props.setSearchedKey('')
    }
  }

  searchText = (searchText) => {

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (searchText != '') {
        this.searchTextInModel(searchText)
      } else {
        this.searchTextInModel('')
      }
    }, 300);
  }

  searchTextInModel = (searchText) => {

    this.props.setSearchedKey(searchText)
    MyStorage.setItem(Constant.ShareInstanceKey.kSelectedAlphabetKey, '')

    if (this.props.selectedSubSection.label == 'Hide') {
      var filterData = this.props.favStockList
      var allRecord = filterData;
      if (searchText != '') {
        allRecord = filterData.filter((o) => {
          if (o['stkName'] != undefined) {
            return o['stkName'].toLowerCase().includes(searchText.toLowerCase()) || o['sym'].toLowerCase().includes(searchText.toLowerCase())
          }
        });
      }

      this.props.addStockData(allRecord)

    } else {
      let filterData = filterArrayByOptions(this.props.arrAllStockData, this.props.selectedSubSection)
      var allRecord = filterData;
      if (searchText != '') {
        allRecord = filterData.filter((o) => {
          if (o['stkName'] != undefined) {
            return o['stkName'].toLowerCase().includes(searchText.toLowerCase()) || o['sym'].toLowerCase().includes(searchText.toLowerCase())
          }
        });
      }

      this.props.addStockData(allRecord)
    }


  }

  renderMobileSearchBar = () => {

    return (

      <SearchBar
        placeholder={'Search...'}
        searchIcon={{ color: 'white' }}
        inputStyle={{ fontSize: fontSizeScale(12), color: 'white', fontFamily: Constant.AppFonts.Roboto.Regular }}
        platform="ios"
        containerStyle={{ flex: 1, backgroundColor: '#3e96f7', borderRadius: fontSizeScale(5) }}
        inputContainerStyle={{ backgroundColor: '#3e96f7', height: fontSizeScale(10) }}
        onChangeText={text => {
          this.setState({
            searchText: text
          })
          this.searchText(text)
        }}
        clearIcon={{ color: 'white', }}
        rightIconContainerStyle={{ color: 'white' }}
        placeholderTextColor={'white'}
        cancelButtonTitle=""
        // showLoading={isLoading}
        loadingProps={{ color: 'white' }}
        value={this.state.searchText}

      />

    )
  }

  renderWebSearchBar = () => {

    return (
      <SearchBar
        placeholder={'Search here'}
        searchIcon={{ color: '#c1c1c1' }}
        inputStyle={[styles.webInputStyle, { outline: 'none', }]}
        platform="ios"
        containerStyle={styles.webContainerStyle}
        inputContainerStyle={styles.webInputContainerStyle}
        onChangeText={text => {
          this.setState({
            searchText: text
          })
          this.searchText(text)
        }}
        clearIcon={null}
        rightIconContainerStyle={{ color: 'white' }}
        placeholderTextColor={'#c1c1c1'}
        value={this.state.searchText}
        cancelButtonTitle=""
      />

    )

  }


  render() {

    return (
      <View style={{ flex: 1 }}>
        {Platform.OS != 'web' && this.renderMobileSearchBar()}
        {Platform.OS == 'web' && this.renderWebSearchBar()}
      </View>

    )

  }
}

const mapStateToProps = (state) => {
  return {
    searchKey: state.stocksReducers.searchKey,
    arrStockData: state.stocksReducers.stockData,
    arrAllStockData: state.stocksReducers.stocksModelData,
    selectedSubSection: state.stocksReducers.selectedSubSection,
    favStockList: state.stockOperationReducers.favStockList,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addStockData: (searchModel) => dispatch(addStockData(searchModel)),
    setSearchedKey: (text) => dispatch(setSearchedKey(text)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchWeb);


const styles = StyleSheet.create({

  webInputStyle: {
    fontSize: 14,
    fontFamily: Constant.AppFonts.Roboto.Regular,

  },

  webContainerStyle: {
    width: '30%',
    height: 45,
    backgroundColor: 'white',
    borderColor: Colors.APP_COLOR_BORDER,
    borderWidth: 2,
    borderRadius: 5
  },

  webInputContainerStyle: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: 'white',
  }


});

