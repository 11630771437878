//Dashboard for mobile app


import * as React from 'react';
import { View, Text, TouchableOpacity, Image, Dimensions, BackHandler } from 'react-native';
import StocksListMobile from '../../Components/Mobile/StocksListMobile'
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { fontSizeScale, sleep } from '../../../Services/AppManager';
import * as Colors from '../../../Utils/Colors';
import FilterList from '../../Components/Mobile/FilterList'
import SearchWeb from '../../Components/WebComponent/SearchWeb';
import SortBottomPopup from '../../Components/Mobile/SortBottomPopup';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Constant from '../../../Utils/Constant';
import MyStorage from '../../../Services/MyStorage';
import Loader from '../../Components/common/LoaderComponent/Loader';

class DashboardContainer extends React.Component {

    componentDidMount() {

        this.props.props.navigation.addListener('focus', () => {
            BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
        });
        this.props.props.navigation.addListener('blur', () => {
            BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
        });
    }

    handleBackButton = () => {
        console.log('exit app')
        BackHandler.exitApp();
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        sleep(200).then(() => {
            this.props.props.navigation.setOptions({
                title: this.props.selectedSection,
            });
        })
    }

    onClosePopup = () => {
        this.RBSheet.close()
    }

    render() {

        return (
            <SafeAreaView edges={['right', 'bottom', 'left']} style={{ flex: 1, backgroundColor: '#f2f4f8' }}>

                <View style={{ flex: 1, backgroundColor: '#f2f4f8' }}>
                    <View style={{ height: fontSizeScale(140), backgroundColor: Colors.APP_COLOR1, borderBottomLeftRadius: fontSizeScale(30), borderBottomRightRadius: fontSizeScale(30) }}>
                        <View style={{ height: fontSizeScale(40), paddingLeft: fontSizeScale(12), paddingRight: fontSizeScale(12), marginTop: fontSizeScale(10) }}>
                            {/* <Text style={{ fontSize: fontSizeScale(20), color: 'white', fontWeight: '500' }}>{this.props.selectedSection}</Text> */}
                            <View style={{ flex: 1, flexDirection: 'row', marginTop: fontSizeScale(0), }}>
                                <SearchWeb />

                                {this.props.selectedSubSection.label == 'Hide' &&
                                    <View style={{ height: fontSizeScale(40), width: fontSizeScale(40), borderRadius: fontSizeScale(5), marginRight: fontSizeScale(5), marginLeft: fontSizeScale(10), backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                                        <SortBottomPopup />
                                    </View>
                                }

                                {(this.props.selectedSubSection.label != 'Hide' && this.props.selectedSection != Constant.AppText.StockETFRanking && this.props.selectedSection != Constant.AppText.StockMutualRanking) &&
                                    <View style={{ height: fontSizeScale(40), width: fontSizeScale(40), borderRadius: fontSizeScale(5), marginRight: fontSizeScale(5), marginLeft: fontSizeScale(10), backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                                        <FilterList />
                                    </View>
                                }

                            </View>
                        </View>
                        {(this.props.selectedSubSection.label != 'Hide' && this.props.selectedSubSection.label != 'All') &&
                            <View style={{ height: fontSizeScale(30), justifyContent: 'center', paddingHorizontal: 12, marginTop: 3 }}>
                                <Text style={{ color: 'white', fontFamily: Constant.AppFonts.Roboto.Medium, fontSize: 14 }}>Showing stocks for {this.props.selectedSubSection.label}</Text>
                            </View>
                        }
                    </View>

                    <View style={{ flex: 1, marginTop: fontSizeScale(-50) }}>
                        <StocksListMobile props={this.props} />
                        {MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus) == '' &&
                            <View style={{ height: 60, }} />}
                    </View>

                    {parseInt(MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)) != 1 &&

                        <View style={{
                            height: 50,
                            width: '100%',
                            position: 'absolute',
                            bottom: 0,
                            backgroundColor: 'white',
                            flexDirection: 'row',
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                            shadowColor: 'rgba(0 0 0, 0.20)',
                            shadowOffset: { width: 0, height: 0 },
                            shadowOpacity: 0.1,
                            shadowRadius: 1,

                        }}>
                            <View style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesome name={'info-circle'} size={30} color={Colors.APP_COLOR1} />
                            </View>
                            <TouchableOpacity onPress={() => this.props.props.navigation.navigate('authScreen')} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={{ color: Colors.APP_DARK_GARY, fontFamily: Constant.AppFonts.Roboto.Regular, fontSize: 14 }}>
                                    {Constant.AppText.YouSeeingSmallFractionOfStocks}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    }

                    {this.props.isVisible && <Loader message={'Fetching...'} />}

                </View>

            </SafeAreaView >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isVisible: state.stocksReducers.isLoadingShowing,
        selectedSection: state.stocksReducers.selectedSection,
        selectedSubSection: state.stocksReducers.selectedSubSection
    }
}


export default connect(mapStateToProps, null)(DashboardContainer);
