import * as React from 'react';
import { View, Text, StyleSheet, Image, TextInput, TouchableOpacity, ImageBackground } from 'react-native'
import Constant from '../../../../Utils/Constant';
import { showFlashBanner } from '../../../../Utils/FlashMessage';
import MyStorage from '../../../../Services/MyStorage';
import * as Colors from '../../../../Utils/Colors';
import Loader from '../../common/LoaderComponent/Loader.web';
import HeaderWeb from '../../../Components/WebComponent/HeaderWeb';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { checkValidation } from '../../../../Services/AppManager';
import { Feather } from '@expo/vector-icons';


import ApiManager from '../../../../Services/Networking/ApiManager';
let apiManager = ApiManager.getInstance()


class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            isLoader: false,
            oldVisible: true,
            newVisible: true,
        }
    }

    onSubmit = () => {


        let oldPassword = checkValidation('text', this.state.oldPassword, 'Old Password')
        if (!oldPassword.status) {
            showFlashBanner(oldPassword.messsage, '1')
            return;
        }

        let newPassword = checkValidation('text', this.state.newPassword, 'New Password')
        if (!newPassword.status) {
            showFlashBanner(newPassword.messsage, '1')
            return;
        }

        this.setState({
            isLoader: true
        })

        let dict = {
            userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
        }
        apiManager.onPostApi(Constant.EndPoint.changePassword, dict).then((responce) => {

            this.setState({
                isLoader: false
            })
            if (responce.json.status == 200) {
                showFlashBanner(responce.json.message)
                this.props.navigation.goBack()
            } else {
                showFlashBanner(responce.json.message, '1')
            }

        }).catch((error) => {
            this.setState({
                isLoader: false
            })
            console.log(error.msg);
        })


    }

    render() {

        return (

            <View style={{ flex: 1 }}>
                <HeaderWeb props={this.props} pageTitleArray={[Constant.AppText.ManageSubscription, 'Change Password']} />

                <ImageBackground key={'mainContainer'}
                    style={styles.mainContainer}
                    source={require('../../../../../assets/web/authBg.png')}>

                    <View key={'shadowContainer'} style={styles.shadowContainer}>

                        <View style={{ flex: 1, justifyContent: 'center', }}>
                            <View key={'header'} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                <Text style={styles.header}>Change Password</Text>
                            </View>

                            <View key={'bodyView'} style={{ paddingLeft: 25, paddingRight: 25, marginTop: 20 }}>

                                <View key={'oldPasswordContainer'} style={styles.emailContainer}>
                                    <Feather name="lock" size={20} color="#2996FF" />

                                    <TextInput
                                        style={[styles.oldPasswordInput, { outline: 'none' }]}
                                        placeholder={'Enter Old Password'}
                                        placeholderTextColor="#A5A5A5"
                                        onChangeText={(text) => this.setState({ oldPassword: text })}
                                        secureTextEntry={this.state.oldVisible}
                                    />
                                    <View style={styles.eye}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({
                                                    oldVisible: !this.state.oldVisible,
                                                })
                                            }}
                                        >
                                            <MaterialCommunityIcons
                                                name={!this.state.oldVisible ? 'eye-outline' : 'eye-off-outline'
                                                }
                                                size={20}
                                                color={Colors.APP_DARK_GARY}
                                            />
                                        </TouchableOpacity>

                                    </View>
                                </View>

                                <View key={'newPasswordContainer'} style={styles.emailContainer}>
                                    <Feather name="lock" size={20} color="#2996FF" />

                                    <TextInput
                                        style={[styles.oldPasswordInput, { outline: 'none' }]}
                                        placeholder={'Enter New Password'}
                                        placeholderTextColor="#A5A5A5"
                                        onChangeText={(text) => this.setState({ newPassword: text })}
                                        secureTextEntry={this.state.newVisible}
                                    />
                                    <View style={styles.eye}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({
                                                    newVisible: !this.state.newVisible,
                                                })
                                            }}
                                        >
                                            <MaterialCommunityIcons
                                                name={this.state.newVisible === false ? 'eye-outline' : 'eye-off-outline'
                                                }
                                                size={20}
                                                color={Colors.APP_DARK_GARY}
                                            />
                                        </TouchableOpacity>

                                    </View>
                                </View>
                                <TouchableOpacity
                                    style={styles.buttonContainer}
                                    onPress={() => this.onSubmit()}
                                >
                                    <Text style={styles.buttonText}>SUBMIT</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={{ flex: 1, width: '50%', }}>
                            <Image key={'sideImage'}
                                style={{ flex: 1, borderBottomRightRadius: 10, borderTopRightRadius: 10 }}
                                source={require('../../../../../assets/web/sideViewImage/NoPath.png')}
                            />
                        </View>

                    </View>
                </ImageBackground>
                {this.state.isLoader && <Loader />}
            </View>
        )

    }
}

export default ChangePassword;

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.APP_COLOR_BACKGROUND,

    },

    shadowContainer: {

        backgroundColor: "white",
        borderRadius: 10,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 2,
        width: '60%',
        height: '80%',
        flexDirection: 'row',
    },

    header: {
        fontSize: 25,
        color: Colors.MAIN_TITLE_COLOR,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    emailContainer: {
        marginTop: 20,
        borderWidth: 2,
        borderColor: Colors.APP_COLOR_BORDER,
        borderRadius: 5,
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderColor: 'rgba(0,0,0,0.1)',
        alignItems: 'center',
        height: 52
    },

    oldPasswordInput: {

        flex: 1,
        paddingHorizontal: 10,
        paddingVertical: 5,
        fontSize: 14,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        color: Colors.MAIN_TITLE_COLOR
    },

    buttonContainer: {
        marginTop: 50,
        backgroundColor: '#007AFF',
        borderRadius: 10,
        paddingVertical: 15,
        paddingHorizontal: 12,
        width: '100%',
    },

    buttonText: {
        fontSize: 16,
        color: '#fff',
        fontWeight: Constant.AppFontWeight.BOLD,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        alignSelf: 'center',
        textTransform: 'uppercase',

    },

    eye: {
        height: 50,
        width: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },


})

