import * as React from 'react';
import { View, Text, StyleSheet, Image, Platform, Alert } from 'react-native'
import Constant from '../../../../Utils/Constant';
import { showFlashBanner } from '../../../../Utils/FlashMessage';
import ApiManager from '../../../../Services/Networking/ApiManager';
import MyStorage from '../../../../Services/MyStorage';
import { Button } from 'react-native-paper';
import { FontAwesome, Entypo } from '@expo/vector-icons';
import * as Colors from '../../../../Utils/Colors';
import PaypalPopup from './PaypalPopup';
import Loader from '../../common/LoaderComponent/Loader.web';
import EmailExistsPopup from './EmailExistsPopup.web';


let apiManager = ApiManager.getInstance()


let ButtonDetails = [
    {
        headerTitle: 'BSN',
        headerSubTitle: 'Subscription',
        amount: Constant.SubscriptionPrice.NORMAL,
        description: Constant.AppText.SubscriptionBox_1,
        subscriptionType: Constant.SubscriptionPlan.NORMAL_PLAN_WEB,
        subscriptionID: Constant.PayPalKey.NORMAL
    }, {
        headerTitle: 'BSN',
        headerSubTitle: 'Premium Subscription',
        amount: Constant.SubscriptionPrice.PREMIUM,
        description: Constant.AppText.SubscriptionBox_2,
        subscriptionType: Constant.SubscriptionPlan.PREMIUM_PLAN_WEB,
        subscriptionID: Constant.PayPalKey.PREMIUM

    }
]

class SubscriptionManager extends React.Component {

    selectedBtn = {}
    constructor(props) {
        super(props);
        this.state = {
            paymentDone: false,
            isLoader: false,
            isShowPaypalPopup: false,
            isShowEmailPopup: false
        }
    }

    storePaypalIDServer = (paypalID, email) => {

        this.setState({
            isShowPaypalPopup: false,
            isLoader: true,
        })

        var dict = {
            'transaction_id': paypalID,
            'devEnvironment': Constant.AppEnvironments.release,
            'appVersion': Constant.AppInfo.version,
            'product_id': this.selectedBtn.subscriptionType,
            'email': email,
        }

        if (MyStorage.getItem(Constant.ShareInstanceKey.kUserID) != '') {
            dict['userId'] = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
        }

        apiManager.onPostApi(Constant.EndPoint.verifyWebPayment, dict).then((responce) => {

            this.setState({
                isLoader: false
            })

            if (responce.json.status == 200 || responce.json.status == 200) {
                MyStorage.setItem(Constant.ShareInstanceKey.kUserID, responce.json.data.userId)
                MyStorage.setItem(Constant.ShareInstanceKey.kEmailId, responce.json.data.email)
                MyStorage.setItem(Constant.ShareInstanceKey.kPaymentStatus, responce.json.data.status)
                MyStorage.setItem(Constant.ShareInstanceKey.kAuthToken, responce.json.data.token)
                MyStorage.setItem(Constant.ShareInstanceKey.kFetchAllStock, '1')

                if (responce.json.status == 200) {
                    showFlashBanner(responce.json.message, '0')
                    MyStorage.setItem(Constant.ShareInstanceKey.kISFirstTypePayment, 'Yes')

                    this.props.onRefresh()

                    // window.location.href = window.location.origin;;
                } else {
                    alert(Constant.ErrorMessage.ServerError)
                    this.setState({
                        isShowEmailPopup: true
                    })
                }

            } else {
                alert(Constant.ErrorMessage.ServerError)
            }

        }).catch((error) => {
            console.log(error.msg);
        })

    }

    onClickLoginBtn = () => {
        this.props.props.navigation.navigate('Login')
    }

    onTappedSubscriptionBtn = (btn) => {

        this.selectedBtn = btn
        this.setState({
            isShowPaypalPopup: true
        })
    }

    onClosePopup = () => {
        this.setState({
            isShowPaypalPopup: false
        })
    }

    loginFromPopup = () => {
        this.onClosePopup()
        this.props.props.navigation.navigate('Login')
    }


    renderSubscriptionBox = () => {

        let jsx = ButtonDetails.map((btn, index) => {

            return (
                <View key={index} style={styles.Column_1}>
                    <View style={styles.HeaderView}>
                        <Image
                            style={{ width: '100%', height: 100 }}
                            source={require('../../../../../assets/payment.png')}
                        />
                        <View style={{ position: 'absolute', top: 8, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: 24, color: 'white', fontFamily: Constant.AppFonts.Roboto.Bold, fontWeight: Constant.AppFontWeight.NORMAL }}>{btn.headerTitle}</Text>
                            <Text style={{ fontSize: 24, color: 'white', fontFamily: Constant.AppFonts.Roboto.Bold, fontWeight: Constant.AppFontWeight.BOLD, }}> {btn.headerSubTitle}</Text>
                        </View>
                    </View>

                    <View style={styles.Body}>
                        <View style={styles.Body_Value}>
                            <FontAwesome name="dollar" size={46} color="black" />
                            <Text style={styles.Body_Value_Text}>{btn.amount}</Text>
                        </View>

                        <View style={styles.Body_Text}>
                            <Text style={styles.Body_Text_Inner}>{btn.description}</Text>
                        </View>
                    </View>

                    <View style={styles.Bottom}>
                        <Button onPress={() => this.onTappedSubscriptionBtn(btn)} mode="Text" color="white" style={styles.Button}> BUY NOW</Button>
                    </View>
                </View>
            )
        });
        return jsx

    }


    render() {

        let userId = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
        let email = MyStorage.getItem(Constant.ShareInstanceKey.kEmailId)
        let endDate = MyStorage.getItem(Constant.ShareInstanceKey.kSubscriptionEndDate)
        let info = `Hello ${email}. Your subscription has been expired on ${endDate}.\nSelect and purchase from below plan to reactivate your subscription`

        return (

            <View key={'mainContainer'} style={styles.mainContainer}>
                <View key={'textContainer'} style={styles.textContainer}>
                    <Text style={{ fontSize: 30, fontFamily: Constant.AppFonts.Roboto.Bold, fontWeight: Constant.AppFontWeight.BOLD, }}>PREMIUM FEATURE</Text>
                </View>


                {userId != '' &&
                    <Text style={styles.loginDetail}>{info}</Text>
                }

                <View key={'subscriptionButtons'} style={styles.subscriptionButtons}>
                    {this.renderSubscriptionBox()}
                </View>

                <View key={'bottomView'} style={styles.bottomView}>

                    {userId == '' &&
                        <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', marginVertical: 30 }}>
                            <Entypo name="chevron-small-right" size={16} color={Colors.APP_DARK_GARY} />
                            <Text style={{ fontSize: 14, fontFamily: Constant.AppFonts.Roboto.Medium, fontWeight: Constant.AppFontWeight.MEDIUM, color: Colors.APP_DARK_GARY }}>Already have an active subscription? </Text>
                            <Text onPress={() => this.onClickLoginBtn()} style={{ color: Colors.APP_COLOR1, fontSize: 15, paddingLeft: 5, textDecorationLine: 'underline', fontFamily: Constant.AppFonts.Roboto.MediumItalic, fontWeight: Constant.AppFontWeight.SEMI_BOLD, fontStyle: 'italic' }}>LOGIN NOW!</Text>
                        </View>
                    }

                    <View style={styles.Section2_Text}>
                        <Text style={styles.textStyle} >{Constant.AppText.SubscriptionAlert_1}</Text>
                    </View>

                    <View style={styles.Section2_Text}>
                        <Text style={styles.textStyle} >{Constant.AppText.SubscriptionAlert_2}</Text>
                    </View>

                    <View style={styles.Section2_Text}>
                        <Text style={styles.textStyle} >{Constant.AppText.SubscriptionAlert_3}</Text>
                    </View>
                </View>

                {
                    this.state.isShowPaypalPopup &&
                    <PaypalPopup btnInfo={this.selectedBtn}
                        onClosePopup={this.onClosePopup.bind()}
                        onSuccess={this.storePaypalIDServer.bind()}
                        onLogin={this.loginFromPopup.bind()}

                    />
                }

                {Platform.OS == 'web' && this.state.isLoader && <Loader />}


                {this.state.isShowEmailPopup &&
                    <EmailExistsPopup />
                }



            </View >
        )

    }
}

export default SubscriptionManager;

const styles = StyleSheet.create({

    mainContainer: {
        flex: 1,
    },

    textContainer: {
        flex: 1,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center'
    },

    subscriptionButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 50,
    },

    bottomView: {
        marginTop: 50,
        justifyContent: 'center'
    },

    HeaderView: {
        alignItems: 'center',

    },

    Column_1: {

        width: 350,
        height: 450,
        backgroundColor: 'white',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderRadius: 10,
        // shadowColor: '#DADADA',
        // shadowOffset: { width: 0, height: 2 },
        // shadowOpacity: 0.25,
        // shadowRadius: 3,
        borderWidth: 1,
        borderColor: Colors.APP_COLOR_BORDER,
        marginLeft: 25,
        marginRight: 25,


        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 2,
    },
    Body: {
        alignItems: 'center',
        flex: 1,
        padding: 20
    },

    Body_Value: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    Body_Value_Text: {
        color: Colors.MAIN_TITLE_COLOR,
        fontSize: 60,
        letterSpacing: 4,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.EXTRA_BOLD,
    },

    Body_Text: {
        paddingTop: 20,
    },

    Body_Text_Inner: {

        textAlign: 'center',
        lineHeight: 30,
        fontSize: 14,
        color: 'rgba(0,0,0,0.8)',
        fontFamily: Constant.AppFonts.Roboto.Medium,
        color: Colors.APP_DARK_GARY,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },
    Bottom: {
        alignItems: 'center',
        height: 60

    },
    Button: {
        backgroundColor: '#007AFF',
        alignItems: 'center',
        width: '60%',
        borderRadius: 20,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },

    Section2_Text: {
        paddingVertical: 10,
        textAlign: 'center',
        flexWrap: 'wrap'
    },

    textStyle: {
        lineHeight: 20,
        fontSize: 14,
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },

    loginDetail: {

        marginTop: 20,
        alignSelf: 'center',
        fontSize: 14,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        color: Colors.APP_DARK_GARY,
        lineHeight: 30
    }

})

