
export default {

    PRE_PERFORMANCE_KEYS: [
        {
            id: '1',
            rowTitle: '1 Mo Total Return',
            rowValueKey: 'prc_1mo_ago',
            prefixString: '',
            suffixString: '%',
        }, {
            id: '2',
            rowTitle: '3 Mo Total Return',
            rowValueKey: 'prc_3mo_ago',
            prefixString: '',
            suffixString: '%',
        }, {
            id: '3',
            rowTitle: '12 Mo Total Return',
            rowValueKey: 'prc_1yr_ago',
            prefixString: '',
            suffixString: '%',
        }, {
            id: '4',
            rowTitle: 'Momentum Grade',
            rowValueKey: 'mtm_grd',
            prefixString: '',
            suffixString: '',
        },
        {
            id: '5',
            rowTitle: '3 Yr Avg Tot Ret',
            rowValueKey: 'prc_3yr_ago',
            prefixString: '',
            suffixString: '%',
        },
        {
            id: '6',
            rowTitle: '5 Yr Avg Tot Ret',
            rowValueKey: 'prc_5yr_ago',
            prefixString: '',
            suffixString: '%',
        },
        {
            id: '7',
            rowTitle: '10 Yr Avg Tot Ret',
            rowValueKey: 'prc_10yr_ago',
            prefixString: '',
            suffixString: '%',
        },
        {
            id: '8',
            rowTitle: 'Performance Grade',
            rowValueKey: 'pre_grd',
            prefixString: '',
            suffixString: '',
        },
        {
            id: '9',
            rowTitle: '07-09 BEAR Mkt Ret',
            rowValueKey: 'adj_cls_20071011',
            prefixString: '',
            suffixString: '%',
        },
        {
            id: '10',
            rowTitle: 'Safety Grade',
            rowValueKey: 'sft_grd',
            prefixString: '',
            suffixString: '',
        },
    ],



    SECTION_UPDATED_KEYS: [
        {
            id: '1',
            categoryName: 'Index1',
            isExpand: true,
            numberOfRow: 2,
            rowKey: ['stk_name', 'cat_des'],
            numberOfColumns: 2,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: 'Market Cap',
                        rowValueKey: 'mkt_cap_int',
                        prefixString: '',
                        suffixString: '',
                    },
                    {
                        id: '2',
                        rowTitle: 'Price/Sales',
                        rowValueKey: 'prc_sls',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '3',
                        rowTitle: 'Size',
                        rowValueKey: 'marketSize',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '4',
                        rowTitle: 'Price/Book',
                        rowValueKey: 'prc_bk',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '5',
                        rowTitle: 'Risk Profile',
                        rowValueKey: 'riskProfile',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '6',
                        rowTitle: 'Price/CashFlow',
                        rowValueKey: 'prc_cf',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '7',
                        rowTitle: 'Annual Revenue',
                        rowValueKey: 'ann_rev',
                        prefixString: '',
                        suffixString: '',
                    },
                    {
                        id: '8',
                        rowTitle: 'Beta',
                        rowValueKey: 'beta',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [

                    {
                        id: '9',
                        rowTitle: 'P/E',
                        rowValueKey: 'trl_pe',
                        prefixString: '',
                        suffixString: '',
                    },
                    {
                        id: '10',
                        rowTitle: 'Avg Volume',
                        rowValueKey: 'vol',
                        prefixString: '',
                        suffixString: '',
                    }
                ],
            ],
        },
        {
            id: '2',
            isExpand: false,
            categoryName: 'Valuation %d',
            categoryChangeWithKey: 'date',
            numberOfColumns: 4,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: 'Last Closing Price',
                        rowValueKey: 'lastClosingPrice',
                        prefixString: '$',
                        suffixString: '',
                    }, {
                        id: '2',
                        rowTitle: 'PEG Ratio',
                        rowValueKey: 'pe',
                        prefixString: '',
                        suffixString: '',
                    },

                ], [
                    {
                        id: '3',
                        rowTitle: 'Next Yr EPS Est',
                        rowValueKey: 'avg_est_nxt_yr',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '4',
                        rowTitle: '5Year Target Price',
                        rowValueKey: 'new_5yr_trg_prc',
                        prefixString: '',
                        suffixString: '',
                    }

                ], [
                    {
                        id: '5',
                        rowTitle: 'Forward PE Ratio',
                        rowValueKey: 'fwd_pe',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '6',
                        rowTitle: 'Dividend Yield',
                        rowValueKey: 'yld',
                        prefixString: '',
                        suffixString: '%',
                    },
                ], [
                    {
                        id: '7',
                        rowTitle: 'Est 5Yr Annual Gr',
                        rowValueKey: 'yr5_grw_rt',
                        prefixString: '',
                        suffixString: '%',
                    }, {
                        id: '8',
                        rowTitle: '5Yr Tot Ret Pot',
                        rowValueKey: 'pot_ret',
                        prefixString: '',
                        suffixString: '',
                    }
                ],
            ],
        }, {
            id: '3',
            categoryName: 'Gunderson Stock Grade',
            isExpand: false,
            numberOfColumns: 1,
            subCategory: [
                {
                    id: '1',
                    rowTitle: '',
                    rowValueKey: 'gcm_grd',
                    prefixString: '',
                    suffixString: '',
                },
            ]
        }, {
            id: '4',
            categoryName: 'Ranking',
            isExpand: false,
            numberOfColumns: 4,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: 'Current',
                        rowValueKey: 'rank',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '2',
                        rowTitle: '3 months ago',
                        rowValueKey: 'rank_3_mon',
                        prefixString: '',
                        suffixString: '',
                    }
                ], [
                    {
                        id: '3',
                        rowTitle: '1 day ago',
                        rowValueKey: 'rank_1',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '4',
                        rowTitle: '6 months ago',
                        rowValueKey: 'rank_6_mon',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '5',
                        rowTitle: '7 days ago',
                        rowValueKey: 'rank_7',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '6',
                        rowTitle: '9 months ago',
                        rowValueKey: 'rank_9_mon',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '7',
                        rowTitle: '1 months ago',
                        rowValueKey: 'rank_1_mon',
                        prefixString: '',
                        suffixString: '',

                    }, {
                        id: '8',
                        rowTitle: '1 year ago',
                        rowValueKey: 'rank_12_mon',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '9',
                        rowTitle: '2 month ago',
                        rowValueKey: 'rank_2_mon',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '10',
                        rowTitle: '18 months ago',
                        rowValueKey: 'rank_18_mon',
                        prefixString: '',
                        suffixString: '',
                    }
                ],
            ]
        }, {
            id: '5',
            categoryName: 'Gunderson Value Grade',
            isExpand: false,
            numberOfColumns: 1,
            subCategory: [
                {
                    id: '1',
                    rowTitle: '',
                    rowValueKey: 'val_grd',
                    prefixString: '',
                    suffixString: '',
                },
            ]
        }, {
            id: '6',
            categoryName: 'Performance of vs S&P 500',
            isExpand: false,
            numberOfColumns: 3,
            subCategory: [],

        }, {
            id: '7',
            categoryName: "Bill's Comments",
            isExpand: false,
            numberOfColumns: 11,// Comment Section
            subCategory: []
        },
    ],




    SECTION_WEBSITE_KEYS: [
        {
            id: '1',
            categoryName: 'Index1',
            isExpand: true,
            numberOfRow: 2,
            rowKey: ['stk_name', 'cat_des'],
            numberOfColumns: 4,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: 'Market Cap',
                        rowValueKey: 'mkt_cap_int',
                        prefixString: '',
                        suffixString: '',
                    },
                    {
                        id: '2',
                        rowTitle: 'Price/Sales',
                        rowValueKey: 'prc_sls',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '3',
                        rowTitle: 'Size',
                        rowValueKey: 'marketSize',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '4',
                        rowTitle: 'Price/Book',
                        rowValueKey: 'prc_bk',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '5',
                        rowTitle: 'Risk Profile',
                        rowValueKey: 'riskProfile',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '6',
                        rowTitle: 'Price/CashFlow',
                        rowValueKey: 'prc_cf',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '7',
                        rowTitle: 'Annual Revenue',
                        rowValueKey: 'ann_rev',
                        prefixString: '',
                        suffixString: '',
                    },
                    {
                        id: '8',
                        rowTitle: 'Beta',
                        rowValueKey: 'beta',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [

                    {
                        id: '9',
                        rowTitle: 'P/E',
                        rowValueKey: 'trl_pe',
                        prefixString: '',
                        suffixString: '',
                    },
                    {
                        id: '10',
                        rowTitle: 'Avg Volume',
                        rowValueKey: 'vol',
                        prefixString: '',
                        suffixString: '',
                    }
                ],
            ],
        },
        {
            id: '2',
            isExpand: false,
            categoryName: 'Valuation %d',
            categoryChangeWithKey: 'date',
            numberOfColumns: 4,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: 'Last Closing Price',
                        rowValueKey: 'prc',
                        prefixString: '$',
                        suffixString: '',
                    }, {
                        id: '2',
                        rowTitle: 'PEG Ratio',
                        rowValueKey: 'PEG Ratio',
                        prefixString: '',
                        suffixString: '',
                    },

                ], [
                    {
                        id: '3',
                        rowTitle: 'Next Yr EPS Est',
                        rowValueKey: 'avg_est_nxt_yr',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '4',
                        rowTitle: '5Year Target Price',
                        rowValueKey: 'new_5yr_trg_prc',
                        prefixString: '',
                        suffixString: '',
                    }

                ], [
                    {
                        id: '5',
                        rowTitle: 'Forward PE Ratio',
                        rowValueKey: 'fwd_pe',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '6',
                        rowTitle: 'Dividend Yield',
                        rowValueKey: 'yld',
                        prefixString: '',
                        suffixString: '%',
                    },
                ], [
                    {
                        id: '7',
                        rowTitle: 'Est 5Yr Annual Gr',
                        rowValueKey: 'yr5_grw_rt',
                        prefixString: '',
                        suffixString: '%',
                    }, {
                        id: '8',
                        rowTitle: '5Yr Tot Ret Pot',
                        rowValueKey: 'pot_ret',
                        prefixString: '',
                        suffixString: '',
                    }
                ],
            ],
        }, {
            id: '3',
            categoryName: 'Gunderson Stock Grade',
            isExpand: false,
            numberOfColumns: 1,
            subCategory: [
                {
                    id: '1',
                    rowTitle: '',
                    rowValueKey: 'gcm_grd',
                    prefixString: '',
                    suffixString: '',
                },
            ]
        }, {
            id: '4',
            categoryName: 'Ranking',
            isExpand: false,
            numberOfColumns: 4,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: 'Current',
                        rowValueKey: 'rank',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '2',
                        rowTitle: '3 months ago',
                        rowValueKey: 'rank_3_mon',
                        prefixString: '',
                        suffixString: '',
                    }
                ], [
                    {
                        id: '3',
                        rowTitle: '1 day ago',
                        rowValueKey: 'rank_1',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '4',
                        rowTitle: '6 months ago',
                        rowValueKey: 'rank_6_mon',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '5',
                        rowTitle: '7 days ago',
                        rowValueKey: 'rank_7',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '6',
                        rowTitle: '9 months ago',
                        rowValueKey: 'rank_9_mon',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '7',
                        rowTitle: '1 months ago',
                        rowValueKey: 'rank_1_mon',
                        prefixString: '',
                        suffixString: '',

                    }, {
                        id: '8',
                        rowTitle: '1 year ago',
                        rowValueKey: 'rank_12_mon',
                        prefixString: '',
                        suffixString: '',
                    },
                ], [
                    {
                        id: '9',
                        rowTitle: '2 month ago',
                        rowValueKey: 'rank_2_mon',
                        prefixString: '',
                        suffixString: '',
                    }, {
                        id: '10',
                        rowTitle: '18 months ago',
                        rowValueKey: 'rank_18_mon',
                        prefixString: '',
                        suffixString: '',
                    }
                ],
            ]
        }, {
            id: '5',
            categoryName: 'Gunderson Value Grade',
            isExpand: false,
            numberOfColumns: 1,
            subCategory: [
                {
                    id: '1',
                    rowTitle: '',
                    rowValueKey: 'val_grd',
                    prefixString: '',
                    suffixString: '',
                },
            ]
        }, {
            id: '6',
            categoryName: 'Performance of vs S&P 500',
            isExpand: false,
            numberOfColumns: 6,
            subCategory: [
                [
                    {
                        id: '1',
                        rowTitle: '1 Mo Total Return',
                        rowValueKey: 'prc_1mo_ago',
                        prefixString: '',
                        suffixString: '%',
                    },
                    // {
                    //     id: '11',
                    //     rowTitle: '2021 YTD Return',
                    //     rowValueKey: 'cls_prc_20201231',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '2',
                        rowTitle: '3 Mo Total Return',
                        rowValueKey: 'prc_3mo_ago',
                        prefixString: '',
                        suffixString: '%',
                    },
                    // {
                    //     id: '12',
                    //     rowTitle: '2020 Total Return',
                    //     rowValueKey: 'ret_2020',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '3',
                        rowTitle: '12 Mo Total Return',
                        rowValueKey: 'prc_1yr_ago',
                        prefixString: '',
                        suffixString: '%',
                    },
                    // {
                    //     id: '13',
                    //     rowTitle: '2019 Total Return',
                    //     rowValueKey: 'ret_2019',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '4',
                        rowTitle: 'Momentum Grade',
                        rowValueKey: 'mtm_grd',
                        prefixString: '',
                        suffixString: '',
                    },
                    // {
                    //     id: '14',
                    //     rowTitle: '2018 Total Return',
                    //     rowValueKey: 'ret_2018',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '5',
                        rowTitle: '3 Yr Avg Tot Ret',
                        rowValueKey: 'prc_3yr_ago',
                        prefixString: '',
                        suffixString: '%',
                    },
                    // {
                    //     id: '15',
                    //     rowTitle: '2017 Total Return',
                    //     rowValueKey: 'ret_2017',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [{
                    id: '6',
                    rowTitle: '5 Yr Avg Tot Ret',
                    rowValueKey: 'prc_5yr_ago',
                    prefixString: '',
                    suffixString: '%',
                },
                    // {
                    //     id: '16',
                    //     rowTitle: '2016 Total Return',
                    //     rowValueKey: 'ret_2016',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '7',
                        rowTitle: '10 Yr Avg Tot Ret',
                        rowValueKey: 'prc_10yr_ago',
                        prefixString: '',
                        suffixString: '%',
                    },
                    // {
                    //     id: '17',
                    //     rowTitle: '2015 Total Return',
                    //     rowValueKey: 'ret_2015',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '8',
                        rowTitle: 'Performance Grade',
                        rowValueKey: 'pre_grd',
                        prefixString: '',
                        suffixString: '',
                    },
                    // {
                    //     id: '18',
                    //     rowTitle: '2014 Total Return',
                    //     rowValueKey: 'ret_2014',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '9',
                        rowTitle: '07-09 BEAR Mkt Ret',
                        rowValueKey: 'adj_cls_20071011',
                        prefixString: '',
                        suffixString: '%',
                    },
                    // {
                    //     id: '19',
                    //     rowTitle: '2013 Total Return',
                    //     rowValueKey: 'ret_2013',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ], [
                    {
                        id: '10',
                        rowTitle: 'Safety Grade',
                        rowValueKey: 'sft_grd',
                        prefixString: '',
                        suffixString: '',
                    },
                    // {
                    //     id: '20',
                    //     rowTitle: '2012 Total Return',
                    //     rowValueKey: 'ret_2012',
                    //     prefixString: '',
                    //     suffixString: '%',
                    // },
                ],
            ],
        }, {
            id: '7',
            categoryName: "Bill's Comments",
            isExpand: false,
            numberOfColumns: 11,// Comment Section
            subCategory: []
        },
    ]




}