
import { Platform } from 'react-native'
import { scale } from 'react-native-size-matters';
import Constant from '../Utils/Constant';
import DynamicFilterOption from "../Models/DynamicFilterOption";
let dynamicFilterOption = DynamicFilterOption.getInstance()
import moment from 'moment';


export const isSubscriptionTierLow = (productID) => {

    if (productID == Constant.SubscriptionPlan.NORMAL || productID == Constant.SubscriptionPlan.NORMAL_ANDROID || productID == Constant.SubscriptionPlan.NORMAL_PLAN_WEB || productID == Constant.SubscriptionPlan.NORMAL_PLAN_MANUALLY) {
        return true;
    }
    return false;

}

export const isHtml = (input) => {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
}

export const subscriptionEndDateLocal = (endDate) => {

    let localDatetime = moment(endDate + '+00:00').local().format('MM-DD-YYYY hh:mm a');
    return localDatetime
}

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
/**
 * @param  {} arr All data in array
 * @param  {} values - What shoud value like- 
 * @param  {} key - Which key to need check the value 
 */
export const filterArrayByValue = (arr, values, key) => {

    var filteredArray;
    if (values.length > 0 && values[0] == "All") {
        filteredArray = arr
    } else {
        filteredArray = arr.filter(function (item) {
            return values.indexOf(item[key]) > -1;
        });
    }

    let sortkey = key == 'momentomGrd' ? "momentomGrd" : "gundersonGrade"
    // return filteredArray.sort(sortArrayByGrade(sortkey));

    return filteredArray.sort(sortArrayByKey('ranking', 'asc'));;

}

export const sortArrayByGrade = (key) => {

    console.log("Comming Keys", key);

    return function (a, b) {
        if (a[key] === b[key]) return 0

        const ap = a[key].split('')
        const bp = b[key].split('')
        if (ap[0] === bp[0]) {
            return (ap[1] === "+" || bp[1] === "-") ? -1 : 1
        }
        return ap[0].localeCompare(bp[0])
    }
}





/**
 * @param  {} arrStocksData All data in array
 * @param  {} selectedObj What should filter the
 * This function helps to filter by name, key and Range. after indefy what should for filter call appropate function.
 */
export const filterArrayByOptions = (arrStocksData, selectedObj) => {

    var filterKey = selectedObj['Name']
    if (selectedObj['data'] != undefined) {
        filterKey = selectedObj['data']
    }

    var filteredData = []

    switch (selectedObj['key']) {
        case 'stkName':
            filteredData = filterArrayByNameStartWith(arrStocksData, filterKey.split(','), selectedObj['key'])
            return filteredData

        case 'ranking':

            let range = selectedObj['data'].split(', ')
            var startRange = range[0];
            var endRange = 0;
            if (range.length == 2) {
                endRange = range[1]
            }
            filteredData = filterArrayByRange(arrStocksData, selectedObj['key'], startRange, endRange)
            return filteredData
        default:

            filteredData = filterArrayByValue(arrStocksData, [filterKey], selectedObj['key'])
            return filteredData
    }

}

/**
 * @param  {} arr - All data in array
 * @param  {} values - What shoud value like- A, A+
 * @param  {} key - which key to need check the value
 */
export const filterArrayByNameStartWith = (arr, values, key) => {

    let filteredArray = arr.filter(function (item) {
        return item[key] != null && values.some(med => item[key].startsWith(med));
    });
    return filteredArray;
}
/**
 * @param  {} arr - All data in array
 * @param  {} key - All data in array
 * @param  {} startRange - Starting value line 100 to 300 show  startRange is 100
 * @param  {} endRange - End value line 100 to 300 show  End is 300
 */
export const filterArrayByRange = (arr, key, startRange, endRange) => {

    var filteredArray = arr.filter(function (item) {
        return item[key] >= startRange && (endRange > 0 ? item[key] <= endRange : true)
    });

    return filteredArray.sort(sortArrayByKey(key, 'asc'));;

}


/**
 * @param  {} key - THis is key to sorting accordinally 
 * @param  {} order - Order like ASC and DESC 
 */
export const sortArrayByKey = (key, order) => {

    var sort_order = 1;
    if (order === "desc") {
        sort_order = -1;
    }
    return function (a, b) {
        if (a[key] < b[key]) {
            return -1 * sort_order;
        } else if (a[key] > b[key]) {
            return 1 * sort_order;
        } else {
            return 0 * sort_order;
        }
    }
}


export const fontSizeScale = (size) => {
    return Platform.OS == 'ios' ? scale(size) : size
}

export const getFinishingChars = () => {
    return ""
}


/**
 * @param  {} selectedSection
 * 
 * This function help to show filter option list according to stocks list
 * 
 */
export const getFilterOption = (selectedSection) => {

    switch (selectedSection) {
        case Constant.AppText.StockMomentum:
            return dynamicFilterOption.momentumFilterOptions
        case Constant.AppText.StockBetterB:
            return Constant.betterFromB
        case 'Company Name':
            return Constant.arrCompanyName
        case Constant.AppText.StockAggressive:
            return Constant.arrRiskProfile
        case Constant.AppText.StockMarketCap:
            return Constant.arrCap
        case Constant.AppText.StockRanking:
            return Constant.arrRank
        case Constant.AppText.AllStocks:
            return dynamicFilterOption.sectorFilterOption
        case Constant.AppText.StockMutualRanking:
            return Constant.arrMatualFund
        case Constant.AppText.StockETFRanking:
            return Constant.arrETF

        default:
            return []
    }

}

/**
 * @param  {} arr - All data in array
 * @param  {} predicate - Key which you wanted to find unique value
 */
export const findUniqueValue = (arr, predicate) => {

    return arr.reduce((a, d) => {

        if (d[predicate] != "" && d[predicate] != null && !a.includes(d[predicate])) { a.push(d[predicate]); }
        return a.sort();
    }, []);

    // console.log(unique);
    // const unique = [...new Set(arr.map(item => item[predicate]))];
    // return unique
}


export const isEmailValid = (email) => {
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = true
    if (email.trim().length <= 0) {
        // console.log('Empty email')
        isValid = false
    } else if (emailRegex.test(email) === false) {
        // console.log('email not valid')
        isValid = false
    }
    return (isValid)
}

export const checkValidation = (type, text, fieldName) => {

    switch (type) {
        case 'text':
            let trimString = text.trim().length
            if (trimString == 0) {
                return {
                    status: false,
                    messsage: `${fieldName} can not be blank`
                }
            }
            break

        case 'email':
            let emailStatus = isEmailValid(text)
            if (!emailStatus) {
                return {
                    status: false,
                    messsage: `Please enter valid ${fieldName}`
                }
            }
        default:
            return {
                status: true,
            }
    }

    return {
        status: true,
    }

}



export const getCapBackgroundColor = (marketSize) => {

    switch (marketSize) {
        case 'Small-Cap':
            return (
                {
                    backgroundColor: '#f8b102'
                }
            )

        case 'Mid-Cap':
            return (
                {
                    backgroundColor: '#ff2001'
                }
            )


        case 'Micro-Cap':
            return (
                {
                    backgroundColor: '#56D330'
                }
            )


        case 'Mega-Cap':
            return (
                {
                    backgroundColor: '#0098ff'
                }
            )


        case 'Large-Cap':
            return (
                {
                    backgroundColor: '#ba3f7a'
                }
            )

        default:
            break;
    }

}



export const getEndPont = (sideMenuTitle, sectionTitle = "") => {

    var xmlFile = ''
    if (sideMenuTitle == 'Grade') {

        switch (sectionTitle) {
            case 'Gunderson Grade A+':
                xmlFile = `v3/gcm${getFinishingChars()}_a1.xml`
                break;

            case 'Gunderson Grade A':
                xmlFile = `v3/gcm${getFinishingChars()}_a2.xml`
                break;

            case 'Gunderson Grade A-':
                xmlFile = `v3/gcm${getFinishingChars()}_a3.xml`
                break;

            case 'Gunderson Grade B+':
                xmlFile = `v3/gcm${getFinishingChars()}_b1.xml`
                break;

            case 'Gunderson Grade B':
                xmlFile = `v3/gcm${getFinishingChars()}_b2.xml`
                break;

            case 'Gunderson Grade B-':
                xmlFile = `v3/gcm${getFinishingChars()}_b3.xml`
                break;

            case 'Gunderson Grade C+':
                xmlFile = `v3/gcm${getFinishingChars()}_c1.xml`
                break;


            case 'Gunderson Grade C':
                xmlFile = `v3/gcm${getFinishingChars()}_c2.xml`
                break;

            case 'Gunderson Grade C-':
                xmlFile = `v3/gcm${getFinishingChars()}_c1.xml`
                break;

            case 'Gunderson Grade D+':
                xmlFile = `v3/gcm${getFinishingChars()}_d1.xml`
                break;

            case 'Gunderson Grade D':
                xmlFile = `v3/gcm${getFinishingChars()}_d2.xml`
                break;

            case 'Gunderson Grade D-':
                xmlFile = `v3/gcm${getFinishingChars()}_d3.xml`
                break;

            case 'Gunderson Grade F+':
                xmlFile = `v3/gcm${getFinishingChars()}_f1.xml`
                break;

            case 'Gunderson Grade F':
                xmlFile = `v3/gcm${getFinishingChars()}_f2.xml`
                break;

            case 'Gunderson Grade NA':
                xmlFile = `v3/gcm${getFinishingChars()}_f2.xml`
                break;

            default:
                xmlFile = `v3/gcm${getFinishingChars()}_na.xml`
                break;
        }

        return xmlFile
    } else if (sideMenuTitle == 'Company Name') {


        switch (sectionTitle) {
            case 'Company Name: A-C':
                xmlFile = `v3/gcm${getFinishingChars()}_ac.xml`
                break;

            case 'Company Name: D-F':
                xmlFile = `v3/gcm${getFinishingChars()}_df.xml`
                break;

            case 'Company Name: G-J':
                xmlFile = `v3/gcm${getFinishingChars()}_gj.xml`
                break;

            case 'Company Name: K-N':
                xmlFile = `v3/gcm${getFinishingChars()}_kn.xml`
                break;

            case 'Company Name: O-S':
                xmlFile = `v3/gcm${getFinishingChars()}_os.xml`
                break;

            case 'Company Name: T-Z':
                xmlFile = `v3/gcm${getFinishingChars()}_tz.xml`
                break;

            default:
                xmlFile = `v3/gcm${getFinishingChars()}_ac.xml`
                break;
        }

        return xmlFile
    } else if (sideMenuTitle == 'Aggressive Rank') {


        switch (sectionTitle) {
            case 'Aggressive: Rank 1-1000':
                xmlFile = `v3/gcm${getFinishingChars()}_sty1a.xml`
                break;

            case 'Aggressive: Rank 1001-2000':
                xmlFile = `v3/gcm${getFinishingChars()}_sty1b.xml`
                break;

            case 'Aggressive: Rank 2001-3000+':
                xmlFile = `v3/gcm${getFinishingChars()}_sty1c.xml`
                break;

            case 'Conservative':
                xmlFile = `v3/gcm${getFinishingChars()}_sty2.xml`
                break;

            case 'Growth & Income':
                xmlFile = `v3/gcm${getFinishingChars()}_sty3.xml`
                break;

            case 'Income':
                xmlFile = `v3/gcm${getFinishingChars()}_sty4.xml`
                break;

            case 'Moderate':
                xmlFile = `v3/gcm${getFinishingChars()}_sty5.xml`
                break;

            case 'Speculative':
                xmlFile = `v3/gcm${getFinishingChars()}_sty6.xml`
                break;

            default:
                xmlFile = `v3/gcm${getFinishingChars()}_sty1a.xml`
                break;

        }
        return xmlFile

    } else if (sideMenuTitle == 'Market Cap') {

        switch (sectionTitle) {
            case 'Micro-Cap':
                xmlFile = `v3/gcm${getFinishingChars()}_siz1.xml`
                break;

            case 'Small-Cap: Rank 1-1000':
                xmlFile = `v3/gcm${getFinishingChars()}_siz2a.xml`
                break;

            case 'Small-Cap: Rank 1001-2000':
                xmlFile = `v3/gcm${getFinishingChars()}_siz2b.xml`
                break;

            case 'Small-Cap: Rank 2001-3000+':
                xmlFile = `v3/gcm${getFinishingChars()}_siz2c.xml`
                break;

            case 'Mid-Cap: Rank 1-1000':
                xmlFile = `v3/gcm${getFinishingChars()}_siz3a.xml`
                break;

            case 'Mid-Cap: Rank 1001-2000':
                xmlFile = `v3/gcm${getFinishingChars()}_siz3b.xml`
                break;

            case 'Mid-Cap: Rank 2001-3000+':
                xmlFile = `v3/gcm${getFinishingChars()}_siz3c.xml`
                break;

            case 'Large-Cap':
                xmlFile = `v3/gcm${getFinishingChars()}_siz4.xml`
                break;

            case 'Mega-Cap':
                xmlFile = `v3/gcm${getFinishingChars()}_siz5.xml`
                break;

            default:
                xmlFile = `v3/gcm${getFinishingChars()}_siz1.xml`
                break;

        }
        return xmlFile
    } else if (sideMenuTitle == 'Ranking') {

        switch (sectionTitle) {
            case 'Rank 1-300':
                xmlFile = `v3/gcm${getFinishingChars()}_rank1.xml`
                break;

            case 'Rank 301-600':
                xmlFile = `v3/gcm${getFinishingChars()}_rank2.xml`
                break;

            case 'Rank 601-900':
                xmlFile = `v3/gcm${getFinishingChars()}_rank3.xml`
                break;

            case 'Rank 901-1200':
                xmlFile = `v3/gcm${getFinishingChars()}_rank4.xml`
                break;

            case 'Rank 1201-1500':
                xmlFile = `v3/gcm${getFinishingChars()}_rank5.xml`
                break;

            case 'Rank 1501-1800':
                xmlFile = `v3/gcm${getFinishingChars()}_rank6.xml`
                break;

            case 'Rank 1801-2100':
                xmlFile = `v3/gcm${getFinishingChars()}_rank7.xml`
                break;

            case 'Rank 2101-2400':
                xmlFile = `v3/gcm${getFinishingChars()}_rank8.xml`
                break;

            case 'Rank 2401-2700':
                xmlFile = `v3/gcm${getFinishingChars()}_rank9.xml`
                break;

            case 'Rank 2701-3000':
                xmlFile = `v3/gcm${getFinishingChars()}_rank10.xml`
                break;

            case 'Rank 3001-3300':
                xmlFile = `v3/gcm${getFinishingChars()}_rank11.xml`
                break;

            case 'Rank 3301-3600':
                xmlFile = `v3/gcm${getFinishingChars()}_rank12.xml`
                break;

            case 'Rank 3601-3900+':
                xmlFile = `v3/gcm${getFinishingChars()}_rank13.xml`
                break;

            case 'Daily Momentum A+':
                xmlFile = `v3/gcm${getFinishingChars()}_mom_a.xml`
                break;


            default:
                xmlFile = `v3/gcm${getFinishingChars()}_rank1.xml`
                break;

        }
        return xmlFile

    }

}