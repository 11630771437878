import { DASHBOARD_DATA, STOCK_DATA, SECTION, SUB_SECTION, API_LOADER, SEARCH_KEY, STOCKS_DATA, PAGE_COUNT, UPDATE_STOCK_DATA, DRAWER_TOGGLE, CLEAR_ALL_DATA, UPDATE_STOCK_MODEL_DATA, OPEN_DRAWER_TOGGLE, SELECTED_SMALL_SIDE_MENU, IS_AUTH_ACTION } from '../actions/types';
import Constant from '../../../Utils/Constant';

const initialState = {
    dashBoardData: [],
    stockData: [],
    selectedSection: Constant.AppText.AllStocks,
    selectedSubSection: Constant.blankSubSection,
    isLoadingShowing: false,
    searchKey: '',
    stocksModelData: [],
    isMobileRefresh: 0,
    pageCount: 0,
    drawerToggle: false,
    selectedSideMenuIndex: -1,
    selectedSideMenuSubIndex: -1,

}

const stocksReducers = (state = initialState, actions) => {
    switch (actions.type) {

        case DASHBOARD_DATA:
            return {
                ...state,
                dashBoardData: actions.payload
            }

        case STOCK_DATA:
            return {
                ...state,
                stockData: actions.payload,
                // isLoadingShowing: false,
                isMobileRefresh: state.isMobileRefresh + 1,
                pageCount: 0
            }

        case UPDATE_STOCK_DATA:

            var oldStock = state.stockData
            if (actions.actionType == 'add') {
                oldStock[actions.actionIndex].favouritesStockList = [actions.payload]
            } else {
                oldStock[actions.actionIndex].favouritesStockList = []
            }
            return {
                ...state,
                stockData: oldStock,
                isMobileRefresh: state.isMobileRefresh + 1,

            }

        case UPDATE_STOCK_MODEL_DATA:

            var oldStock = state.stocksModelData
            if (actions.actionType == 'add') {
                oldStock[actions.actionIndex].favouritesStockList = [actions.payload]
            } else {
                oldStock[actions.actionIndex].favouritesStockList = []
            }
            return {
                ...state,
                stocksModelData: oldStock,
                isMobileRefresh: state.isMobileRefresh + 1,

            }


        case SECTION:
            return {
                ...state,
                selectedSection: actions.payload
            }

        case SUB_SECTION:
            return {
                ...state,
                selectedSubSection: actions.payload
            }

        case API_LOADER:

            return {
                ...state,
                isLoadingShowing: actions.payload
            }

        case SEARCH_KEY:

            return {
                ...state,
                searchKey: actions.payload
            }

        case STOCKS_DATA:
            return {
                ...state,
                stocksModelData: actions.payload
            }

        case PAGE_COUNT:
            return {
                ...state,
                pageCount: actions.payload
            }

        case DRAWER_TOGGLE:
            return {
                ...state,
                drawerToggle: !state.drawerToggle
            }

        case OPEN_DRAWER_TOGGLE:
            return {
                ...state,
                drawerToggle: true
            }

        case SELECTED_SMALL_SIDE_MENU:
            return {
                ...state,
                selectedSideMenuIndex: actions.payload,
                selectedSideMenuSubIndex: actions.sideMenuSubIndex
            }

        case CLEAR_ALL_DATA:
            return {
                ...state,
                dashBoardData: [],
                stockData: [],
                selectedSection: Constant.AppText.StockMomentum,
                selectedSubSection: Constant.arrGrade[0],
                searchKey: '',
                stocksModelData: [],
                isMobileRefresh: 0,
                pageCount: 0,
            }

        default:
            // console.log(state);
            return state;
    }
}

export default stocksReducers;