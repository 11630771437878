//This class is used to check user's active subscription and login status, based on this navigate the user to other pages

import React from 'react';
import { Platform } from 'react-native';
import { Provider } from 'react-redux';
import App from './App'
const store = configStore()
import configStore from '../src/Services/Redux/store';
import FlashMessage from "react-native-flash-message";
import MyStorage from '../src/Services/MyStorage';
import SplashScreen from 'react-native-splash-screen';
import ApiManager from '../src/Services/Networking/ApiManager';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constant from '../src/Utils/Constant';
import { subscriptionEndDateLocal } from '../src/Services/AppManager';

let apiManager = ApiManager.getInstance()

export default class MainRoot extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isAppLoading: true
        }
    }

    componentDidMount() {

        MyStorage.sync()

        if (Platform.OS != 'web') {
            AsyncStorage.multiGet([Constant.ShareInstanceKey.kUserID, Constant.ShareInstanceKey.kAuthToken]).then((storedData) => {

                var userID = ''
                var token = ''
                storedData.map((value) => {
                    if (value[0] == Constant.ShareInstanceKey.kUserID) {
                        userID = value[1] || ''
                    }
                    if (value[0] == Constant.ShareInstanceKey.kAuthToken) {
                        token = value[1] || ''
                    }
                })

                MyStorage.setItem(Constant.ShareInstanceKey.kUserID, userID)
                MyStorage.setItem(Constant.ShareInstanceKey.kAuthToken, token)

                if (userID != null && userID != undefined && userID != '') {

                    var dict = {
                        userId: userID,
                    }

                    apiManager.onPostApi(Constant.EndPoint.getSubsription, dict).then((responce) => {

                        if (responce.json.status == 200) {
                            // console.log('AYYA responce', responce.json);
                            MyStorage.setItem(Constant.ShareInstanceKey.kEmailId, responce.json.data[0]['email'])

                            let endTimeLocal = subscriptionEndDateLocal(responce.json.data[0]['subscriptionEndDate'])

                            MyStorage.setItem(Constant.ShareInstanceKey.kSubscriptionEndDate, endTimeLocal)
                            MyStorage.setItem(Constant.ShareInstanceKey.kPaymentStatus, responce.json.data[0]['status'])
                        }

                        this.setState({ isAppLoading: false })
                        SplashScreen.hide();
                    }).catch((error) => {
                        // console.log('Error in getSubsription', error);
                        this.setState({ isAppLoading: false })
                        SplashScreen.hide();
                    })
                } else {
                    this.setState({ isAppLoading: false })
                    SplashScreen.hide();
                }
            });
        } else {
            this.setState({ isAppLoading: false })
        }
    }

    render() {
        return (
            <Provider store={store}>
                {!this.state.isAppLoading && <App />}
                <FlashMessage />
            </Provider>
        );
    }
}
