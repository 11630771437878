
import * as React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import * as Colors from '../../../Utils/Colors';
import Constant from '../../../Utils/Constant';

class HeaderWeb extends React.Component {

    constructor(props) {
        super(props);
    }


    pageTitleView = () => {
        var pageTitle = ''
        pageTitle = this.props.pageTitleArray.map((pageName, index) => {
            return (
                <View key={`headingView_${index}`} style={styles.headingView} >
                    <View key={'dividerView'} style={styles.dividerView} />
                    <Text onPress={() => this.onPressLabel(pageName)} style={styles.pageText} >{pageName}</Text>
                </View>
            )
        })
        return pageTitle
    }

    handleHomeIconClick = () => {
        window.location.href = window.location.origin;

    }

    onPressLabel = (pageName) => {

        if (pageName == Constant.AppText.ManageSubscription && this.props.pageTitleArray.length > 1) {
            this.props.props.navigation.goBack()
        }
    }

    render() {

        return (
            <View key={'mainContainer'} style={styles.mainContainer}>
                <FontAwesome5 name={'home'} color={Colors.APP_COLOR1} size={16} onPress={() => this.handleHomeIconClick()} />
                <Text style={styles.companyText} >{Constant.AppText.appTitle}</Text>
                {this.props.pageTitleArray != undefined && this.pageTitleView()}
            </View>
        );
    }
}

export default HeaderWeb;


const styles = StyleSheet.create({

    mainContainer: {
        height: 45,
        backgroundColor: 'white',
        paddingLeft: 25,
        alignItems: 'center',
        flexDirection: 'row'
    },

    headingView: {
        marginLeft: 15,
        flexDirection: 'row'
    },

    companyText: {
        marginLeft: 15,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        color: Colors.MAIN_TITLE_COLOR,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    dividerView: {
        height: 15,
        width: 1,
        backgroundColor: Colors.APP_COLOR_BORDER
    },

    pageText: {
        marginLeft: 15,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        color: Colors.APP_DARK_GARY,
        // textTransform: 'capitalize'
    },


});
