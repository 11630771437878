//Home for web and app both.
//Get all stocks and navigate to the stock list based on platform

import * as React from 'react';
import { View, Platform } from 'react-native';
import MobileHome from '../../Containers/MobileContainers/DashboardContainer';
import WebHome from '../../Containers/WebContainers/Home';
import MyStorage from '../../../Services/MyStorage';
import Constant from '../../../Utils/Constant';


import { connect } from 'react-redux';
import { getAllStockList, addStockData, getFavList, saveSection, saveSubSection, changeSideMenuIndex } from '../../../Services/Redux/actions/stocksAction';
import { filterArrayByValue } from '../../../Services/AppManager';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DynamicFilterOption from "../../../Models/DynamicFilterOption";

let dynamicFilterOption = DynamicFilterOption.getInstance()

var moment = require('moment');


class MainContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.callAPItoFavList()

        this.focusListener = this.props.navigation.addListener('focus', () => {
            this.callAPItoALLStocks()
        });
    }


    callAPItoFavList = () => {
        //  Call  only when user  loggedin
        AsyncStorage.getItem(Constant.ShareInstanceKey.kUserID).then((userid) => {
            var dict = {}
            if (userid != undefined && userid != null && userid != '') {
                dict['userId'] = userid
                this.props.getFavList(dict)
            }
        })
    }

    callAPItoALLStocks = () => {

        AsyncStorage.getItem(Constant.ShareInstanceKey.kUserID).then((userid) => {
            var dict = {}
            if (userid != undefined && userid != null && userid != '') {
                dict['userId'] = userid
            }


            //  Use this for if user login and logout then should refresh all stocks
            let isFetch = MyStorage.getItem(Constant.ShareInstanceKey.kFetchAllStock)

            // Check Crom time to refresh all Data
            let cronStringTime = this.props.cronJobTimeUTC
            let cronDateTime = moment(cronStringTime, "DD-MM-YYYY HH:mm:ss").utc().valueOf();
            var isCronTime = false
            let lastSynceTime = MyStorage.getItem(Constant.ShareInstanceKey.kLastSyncAllStocks)
            let lastSynceTimeStamp = moment(lastSynceTime, "DD-MM-YYYY HH:mm:ss").utc().valueOf();
            if (cronDateTime < lastSynceTimeStamp) {
                isCronTime = true
            }

            if (this.props.stocksModel.length == 0 || isFetch == '1' || isCronTime) {
                MyStorage.setItem(Constant.ShareInstanceKey.kFetchAllStock, '0')
                MyStorage.setItem(Constant.ShareInstanceKey.kSelectedAlphabetKey, '')

                this.props.getStocks(dict).then(() => {

                    // Update last synce time to refresh all stocks
                    var currentTime = moment().format("DD-MM-YYYY HH:mm:ss");
                    MyStorage.setItem(Constant.ShareInstanceKey.kLastSyncAllStocks, currentTime)


                    // If user on List page and try to refresh all stock reset the section values
                    var subSection = this.props.selectedSubSection
                    if (subSection.label == 'Hide') {
                        subSection = dynamicFilterOption.sectorFilterOption[0] || this.props.selectedSubSection
                        this.props.saveNewSection(Constant.AppText.AllStocks)
                        this.props.saveNewSubSection(subSection)
                        this.props.changeSideMenuIndex(0, 1)
                    }

                    var filterValue = subSection['Name']
                    if (subSection['data'] != undefined) {
                        filterValue = subSection['data']
                    }

                    let filterData = filterArrayByValue(this.props.stocksModel, [filterValue], subSection.key);
                    this.props.addStockData(filterData)

                }).catch(() => {
                    console.log('on error');
                });
            }
        });

    }




    //navigate to the stock list screen
    loadHome = () => {
        if (Platform.OS === 'web') {
            return (
                <WebHome props={this.props} />
            )
        }
        return (
            <MobileHome props={this.props} />
        )
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                {this.loadHome()}
            </View>
        );
    }
}


const mapStateToProps = (state) => {

    return {
        selectedSubSection: state.stocksReducers.selectedSubSection,
        stocksModel: state.stocksReducers.stocksModelData,
        cronJobTimeUTC: state.stockOperationReducers.cronJobTimeUTC

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStocks: (req) => dispatch(getAllStockList(req)),
        addStockData: (filterData) => dispatch(addStockData(filterData)),
        getFavList: (req) => dispatch(getFavList(req)),
        saveNewSection: (sactionName) => dispatch(saveSection(sactionName)),
        saveNewSubSection: (subName) => dispatch(saveSubSection(subName)),
        changeSideMenuIndex: (index) => dispatch(changeSideMenuIndex(index, 1)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);

