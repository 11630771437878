
import * as React from 'react';
import { View, Text, ScrollView, StyleSheet, TouchableOpacity, Dimensions, Image } from 'react-native';
import { fontSizeScale, sortArrayByKey, filterArrayByValue } from '../../../Services/AppManager';
import { connect } from 'react-redux';
import { addStockData, updatePageCount, favStockDetails, getFavStocksList } from '../../../Services/Redux/actions/stocksAction';

import { DataTable, ProgressBar } from 'react-native-paper';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import * as Colors from '../../../Utils/Colors';
import Constant from '../../../Utils/Constant';
import TablePagination from '@material-ui/core/TablePagination';
import MyStorage from '../../../Services/MyStorage';


var windowHeight = Dimensions.get('window').height;

class StocksListWeb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedIndex: -1,
            sortedType: 'asc',
            perPage: 200,
            refreshView: 0
        }
    }

    handleResize = () => {
        console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        windowHeight = Dimensions.get('window').height;
        this.setState({
            refreshView: this.state.refreshView + 1
        })
    }


    componentDidMount() {
        window.addEventListener('resize', this.handleResize)

        this.focusListener = this.props.props.navigation.addListener('focus', () => {

            // console.log('this.props.selectedSectionObj.label', this.props.selectedSectionObj.label);
            setTimeout(() => {
                console.log('this.props.selectedSectionObj.OutSIde', this.props.selectedSectionObj.label);
                if (this.props.selectedSectionObj.label == 'Hide') {
                    let allFav = this.props.favList
                    let selectedList = allFav.filter(x => x.name == this.props.selectedSection);
                    // console.log(selectedList);
                    if (selectedList.length > 0) {
                        let dict = {
                            userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
                            listId: selectedList[0].favListid
                        }
                        this.props.getFavStocksList(dict)
                    }
                }
            }, 1000)

        });
    }

    componentWillUnmount() {
        // this.focusListener.remove();
    }


    getBackGroundColor = (index) => {
        if (index % 2 == 1) {
            return { backgroundColor: Colors.TABLE_CELL_BG, borderBottomWidth: 0 }
        }
        return { backgroundColor: 'white', borderBottomWidth: 0 }
    }

    onClickCell = (item, index) => {
        // console.log(item);
        this.props.props.navigation.navigate('StockDetailsContainer', { symbol: item.sym, sector: item.stkName })
    }


    renderCell = () => {

        if (this.props.stockData.length == 0) {
            return (
                <View style={{ height: 300, justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                        style={{ width: 100, height: 100 }}
                        source={require('../../../../assets/Empty.png')}
                    />
                    <Text style={{ fontSize: 13, fontFamily: Constant.AppFonts.Roboto.Regular, marginTop: 20 }}> No Data to Display for {this.props.selectedSectionObj.label} </Text>
                </View>
            )
        }

        let jsx = this.props.stockData.slice(
            this.props.page * this.state.perPage,
            this.props.page * this.state.perPage + this.state.perPage,
        ).map((item, index) => {

            const momeontValue = () => {

                if (this.props.selectedSection == Constant.AppText.StockMarketCap) {
                    return `${item.marketSize} (${item.marketCap})`
                }
                if (this.props.selectedSection == Constant.AppText.StockAggressive) {
                    return item.riskProfile
                }
                return item.momentomGrd //parseFloat(item.momentom).toFixed(2)
            }


            return (

                <DataTable.Row key={index} style={this.getBackGroundColor(index)} >
                    <DataTable.Cell onPress={() => this.onClickCell(item, index)} style={[styles.styleTitle, styles.styleBorder, { flex: 0.5, }]} >
                        <Text style={styles.styleTDText}>{(item.ranking == 0 || item.ranking == Constant.RankingValue.rank) ? "N/A" : item.ranking}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell onPress={() => this.onClickCell(item, index)} style={[styles.styleTitle, styles.styleBorder, { flex: 1.5, paddingLeft: 10, paddingRight: 10 }]} >
                        <View style={{}}>
                            <Text style={{ color: Colors.APP_COLOR1, fontSize: 14, fontFamily: Constant.AppFonts.Roboto.Medium, fontWeight: Constant.AppFontWeight.MEDIUM }}>{item.sym}</Text>
                            <Text style={{ color: Colors.APP_DARK_GARY, fontSize: 12, fontFamily: Constant.AppFonts.Roboto.Regular, fontWeight: Constant.AppFontWeight.NORMAL, marginTop: 3 }} >{item.stkName}</Text>
                        </View>

                    </DataTable.Cell>
                    <DataTable.Cell onPress={() => this.onClickCell(item, index)} numeric style={[styles.styleTitle, styles.styleBorder, { flex: 1, paddingLeft: 10, paddingRight: 10 }]} >
                        <Text style={styles.styleTDText}>${item.lastClosingPrice}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell onPress={() => this.onClickCell(item, index)} style={[styles.styleTitle, styles.styleBorder, { flex: 1, paddingLeft: 10, paddingRight: 10 }]} >
                        <Text style={styles.styleTDText}>{(this.props.selectedSection == Constant.AppText.StockMomentum) ? item.gundersonGrade : item.gundersonGrade}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell onPress={() => this.onClickCell(item, index)} style={[styles.styleTitle, { flex: 1, paddingLeft: 10, paddingRight: 10 }]} >
                        <Text style={styles.styleTDText}>{momeontValue()}</Text>
                    </DataTable.Cell>
                </DataTable.Row>
            )
        });

        return jsx
    }

    getKeyForSorting = (index) => {

        switch (index) {
            case 0:
                return 'ranking'
            case 1:
                return 'sym'
            case 2:
                return 'lastClosingPrice'
            case 3:
                return 'gundersonGrade'
            case 4:
                return 'momentom'
            default:
                return ''
        }

    }


    onClickSortByIndex = (index) => {

        var sortedType = 'asc'
        if (this.state.sortedIndex == index) {
            sortedType = this.state.sortedType == 'asc' ? 'desc' : 'asc'
        }

        let currentShowingData = this.props.stockData
        let sortedData = currentShowingData.sort(sortArrayByKey(this.getKeyForSorting(index), sortedType))
        this.props.addStockData(sortedData)

        this.setState({
            sortedType: sortedType,
            sortedIndex: index,
        })

    }

    getTotalCount = () => {

        var allRecord = this.props.stockData
        if (allRecord != undefined) {
            return allRecord.length
        }
        return 0
    }


    momeontTitle = () => {

        if (this.props.selectedSection == Constant.AppText.StockMarketCap) {
            return "Market Cap"
        }
        if (this.props.selectedSection == Constant.AppText.StockAggressive) {
            return "Risk profile"
        }
        return "Momentum Grade"
    }

    onGotoAuth = () => {

        // this.props.onClickAuthPage(true)
        this.props.props.navigation.navigate('authScreen')
    }



    render() {


        // console.log('this.props.selectedSectionObj.label', this.props.selectedSection);

        const handleChangePage = (event, newPage) => {
            this.props.updatePageCount(newPage)
        };

        const handleChangeRowsPerPage = (event) => {
            this.props.updatePageCount(0)
            this.setState({
                perPage: parseInt(event.target.value, 10)
            });
        };

        return (

            <View style={{ flex: 1, }}>

                <DataTable>
                    <DataTable.Header style={styles.header}>
                        {/* onPress={() => this.onClickSortByIndex(0)}  */}
                        <View style={[styles.styleTitleView, styles.styleBorder, { flex: 0.5 }]}>
                            <Text style={styles.styleTitleText}>Rank</Text>
                            {/* <FontAwesome name={'sort'} color={'#acadad'} size={20} /> */}
                        </View>

                        {/* onPress={() => this.onClickSortByIndex(1)} */}
                        <View style={[styles.styleTitleView, styles.styleBorder, { flex: 1.5 }]}>
                            <Text style={styles.styleTitleText}>Ticker</Text>
                            {/* <FontAwesome name={'sort'} color={'#acadad'} size={20} /> */}
                        </View>

                        {/* onPress={() => this.onClickSortByIndex(2)} */}

                        <View style={[styles.styleTitleView, styles.styleBorder, { flex: 1 }]}>
                            <Text style={styles.styleTitleText}>Last Closing Price</Text>
                            {/* <FontAwesome name={'sort'} color={'#acadad'} size={20} /> */}
                        </View>

                        {/* onPress={() => this.onClickSortByIndex(3)} */}
                        <View style={[styles.styleTitleView, styles.styleBorder, { flex: 1 }]}>
                            <Text style={styles.styleTitleText}>{(this.props.selectedSection == Constant.AppText.StockMomentum) ? 'Gunderson Grade' : 'Gunderson Grade'}</Text>
                            {/* <FontAwesome name={'sort'} color={'#acadad'} size={20} /> */}
                        </View>

                        {/* onPress={() => this.onClickSortByIndex(4)}  */}
                        <View style={[styles.styleTitleView, { flex: 1, }]}>
                            <Text style={styles.styleTitleText}>{this.momeontTitle()}</Text>
                            {/* <FontAwesome name={'sort'} color={'#acadad'} size={20} /> */}
                        </View>

                    </DataTable.Header>

                    <ScrollView style={{ maxHeight: windowHeight - 400 }}>
                        {/* <ScrollView > */}
                        {this.renderCell()}

                    </ScrollView>
                    <View key={'separatorView'} style={styles.separatorView} />

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                        {parseInt(MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)) != 1 &&

                            <View style={{ flexDirection: 'row', }}>
                                <View style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center' }}>
                                    <FontAwesome name={'info-circle'} size={25} color={Colors.APP_COLOR1} />
                                </View>
                                <TouchableOpacity onPress={() => this.onGotoAuth()} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ color: Colors.APP_DARK_GARY, fontFamily: Constant.AppFonts.Roboto.Regular, fontSize: 14, fontWeight: Constant.AppFontWeight.NORMAL }}>
                                        {Constant.AppText.YouSeeingSmallFractionOfStocks}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        }
                        <View />

                        {this.getTotalCount() > 0 &&

                            <TablePagination
                                rowsPerPageOptions={[50, 100, 150, 200]}
                                component="div"
                                count={this.getTotalCount()}
                                rowsPerPage={this.state.perPage}
                                page={this.props.page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            // labelRowsPerPage={"Rows/page"}
                            />

                        }
                    </View>

                </DataTable>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stockData: state.stocksReducers.stockData,
        selectedSection: state.stocksReducers.selectedSection,
        selectedSectionObj: state.stocksReducers.selectedSubSection,
        isLoadingShowing: state.stocksReducers.isLoadingShowing,
        page: state.stocksReducers.pageCount,
        favList: state.stockOperationReducers.favList,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addStockData: (sortedData) => dispatch(addStockData(sortedData)),
        updatePageCount: (page) => dispatch(updatePageCount(page)),
        favStockDetails: (favDetails) => dispatch(favStockDetails(favDetails)),
        getFavStocksList: (req) => dispatch(getFavStocksList(req, false)),
        onClickAuthPage: (status) => dispatch(onClickAuthPage(status)),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StocksListWeb);


const styles = StyleSheet.create({

    header: {
        flexDirection: "row",
        height: fontSizeScale(45),
        backgroundColor: Colors.APP_COLOR_BORDER,
        borderBottomWidth: 0,

    },

    styleTitle: {
        paddingLeft: 10,
        paddingRight: 10
    },

    styleTitleView: {

        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },

    styleBorder: {
        borderRightColor: "#E5E5E5",
        borderRightWidth: fontSizeScale(2)
    },

    styleTitleText: {

        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontSize: fontSizeScale(14),
        fontWeight: Constant.AppFontWeight.SEMI_BOLD,
        color: Colors.APP_DARK_GARY
    },

    styleTDText: {
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL,
        fontSize: 14,
        color: Colors.APP_DARK_GARY
    },
    separatorView: {
        height: 2,
        backgroundColor: Colors.APP_COLOR_BORDER
    },
})
