//Data source for side menu

import Constant from '../src/Utils/Constant'
import { Platform } from 'react-native'

export default {

    StockRankings: {
        id: '1',
        categoryName: Constant.AppText.SideMenu_Title_StockRankings,
        icon: 'bar-chart',
        isExpand: true,
        subCategory: [

            {
                id: '0',
                name: Constant.AppText.Search,
                isSelected: false,
            },

            {
                id: '1',
                name: Constant.AppText.AllStocks,
                isSelected: true,
            },
            {
                id: '2',
                name: Constant.AppText.StockMomentum,
                isSelected: false,
            },
            {
                id: '3',
                name: Constant.AppText.StockBetterB,
                isSelected: false,
            },
            {
                id: '4',
                name: Constant.AppText.StockRanking,
                isSelected: false,
            },
            {
                id: '5',
                name: Constant.AppText.StockMarketCap,
                isSelected: false,
            },

            {
                id: '6',
                name: Constant.AppText.StockAggressive,
                isSelected: false,
            },
            {
                id: '7',
                name: Constant.AppText.StockMutualRanking,
                isSelected: false,
            },
            {
                id: '8',
                name: Constant.AppText.StockETFRanking,
                isSelected: false,
            },
        ],
    },

    MyLists: {
        id: '2',
        categoryName: Constant.AppText.SideMenu_Title_MyLists,
        icon: 'list-ul',
        isExpand: false,
        subCategory: [],
    },

    AboutBestStocksNow: {

        id: '3',
        categoryName: Constant.AppText.SideMenu_Title_AboutBestStocksNow,
        icon: 'about',
        imageIcon: Platform.OS == 'web' ? require('../assets/web/about.png') : require('../assets/mobile/about.png'),
        isExpand: false,
        subCategory: [
            {
                id: '1',
                name: "About Bill Gunderson",
                isSelected: false,
                websiteUrl: Constant.API.ABOUT_GUNDERSON
            },
            {
                id: '2',
                name: "Privacy Policy",
                isSelected: false,
                websiteUrl: Constant.API.PRIVACY_POLICY
            },
            {
                id: '3',
                name: "Terms & Conditions",
                isSelected: false,
                websiteUrl: Constant.API.T_C
            },
        ],
    },


    BSNShowsAndMore: {
        id: '4',
        categoryName: Constant.AppText.SideMenu_Title_BSNShowsAndMore,
        icon: 'line-chart',
        isExpand: false,
        subCategory: [
            {
                id: '1',
                name: "Daily Radio show/Podcast",
                isSelected: false,
                websiteUrl: Constant.API.RADIO_SHOW

            },
            {
                id: '2',
                name: "Webinar Schedule and Archives",
                isSelected: false,
                websiteUrl: Constant.API.UPCOMING_WEBNIAR

            },
            {
                id: '3',
                name: "Bill Gunderson Daily Twitter Feed",
                isSelected: false,
                websiteUrl: Constant.API.TWITTER_SHOW,
                type: 'inside'

            },
        ],
    },


    AccountManagement: {
        id: '5',
        categoryName: Constant.AppText.SideMenu_Title_AccountManagement,
        icon: 'user',
        isExpand: false,
        subCategory: [
            {
                id: '1',
                name: "Manage Subscription",
                isSelected: false,
            },
            {
                id: '2',
                name: "Technical Support",
                isSelected: false,
                websiteUrl: 'https://www.gundersoncapital.com/get-started/'

            },

        ],
    },
}
