
import * as React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
const windowHeight = Dimensions.get('window').height;

class WebTwitterFeed extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (

            <View key={'headerContainer'} style={styles.headerContainer}>
                <HeaderWeb props={this.props} pageTitleArray={['Tweets']} />
                <View key={'mainContainer'} style={styles.mainContainer}>
                    <View style={{ width: '80%' }}>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="billgunderson"
                            options={{ height: windowHeight - 160, }}
                        />

                    </View>

                </View>
            </View>

        );
    }
}

export default WebTwitterFeed;


const styles = StyleSheet.create({

    headerContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
    },

    mainContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
        // padding: 30,
        paddingTop: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },

});
