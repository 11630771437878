//Side Menu for both web and app
//Opened state for web

import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform, Image, Linking } from 'react-native';
import { Collapse, CollapseHeader, CollapseBody, } from 'accordion-collapse-react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { DrawerContentScrollView } from '@react-navigation/drawer';

import { connect } from 'react-redux';
import { getStockList, saveSection, saveSubSection, addStockData, getFavStocksList, createFavList, changeSideMenuIndex, onClickAuthPage } from '../src/Services/Redux/actions/stocksAction';
import { fontSizeScale, getFilterOption, filterArrayByOptions } from '../src/Services/AppManager';
import Constant from '../src/Utils/Constant';
import * as Colors from '../src/Utils/Colors';
import MyStorage from '../src/Services/MyStorage';
import SideMenuOptionList from './SideMenuOptionList';


var CONTENT = [];

class ExpendableDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            refesh: 1,
        }
    }

    openWebUrl = (obj) => {

        if (Platform.OS == 'web') {
            if (obj.type != undefined) {
                this.props.props.navigation.navigate('WebTwitterFeed')
            } else {
                window.open(obj.websiteUrl, '_blank');
            }
        } else {

            if (obj.type != undefined) {
                this.props.props.navigation.navigate('Twitter')
            } else {
                Linking.canOpenURL(obj.websiteUrl).then((supported) => {
                    // console.log('Url Support', supported);
                    if (supported) {
                        return Linking.openURL(obj.websiteUrl);
                    }
                });
            }
        }
    }

    getUserList = (sectionObj) => {

        MyStorage.setItem(Constant.ShareInstanceKey.kSelectedAlphabetKey, '')

        let getOPtion = getFilterOption(sectionObj.name)[0]
        let filterData = filterArrayByOptions(this.props.stocksModel, getOPtion)
        this.props.addStockData(filterData)
        this.props.saveNewSection(sectionObj.name)
        this.props.saveNewSubSection(getOPtion)
    }

    getFavStockList = (sectionObj) => {

        let dict = {
            userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
            listId: sectionObj.id
        }
        this.props.getFavStocksList(dict)
        this.props.saveNewSection(sectionObj.name)
        this.props.saveNewSubSection(Constant.blankSubSection)
    }

    onChangeExpand = (item) => {
        let index = CONTENT.indexOf(item)
        CONTENT.map(function (x) {
            x.isExpand = false;
            return x
        });
        CONTENT[index].isExpand = true
        if (CONTENT[index].categoryName == 'My Lists') {

            let paymentStatus = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)
            let allFav = this.props.favList
            var subCatArr = []
            if (parseInt(paymentStatus) == 1) {

                allFav.map((item) => {
                    let dict = {
                        id: item.favListid,
                        name: item.name,
                        isSelected: false,
                    }
                    subCatArr.push(dict)
                });
            }

            CONTENT[index].subCategory = subCatArr
        }
        this.setState({ refesh: this.state.refesh + 1 })

    }

    onClickSubmenues = (subItem, index, parentItem) => {

        if (subItem.name == Constant.AppText.Search) {

            this.setState({ refesh: this.state.refesh + 1 })
            if (Platform.OS == 'web') {
                this.props.props.navigation.navigate('SearchWeb')

            } else {
                this.props.props.navigation.navigate('search')
            }
            return
        }

        if (subItem.websiteUrl != undefined) {
            this.openWebUrl(subItem)
            return
        }

        CONTENT.map(function (x) {
            x.isExpand = false;
            return x
        });
        CONTENT[index].isExpand = true

        CONTENT.map(function (x) {
            x.subCategory.map(function (y) {
                y.isSelected = false;
                return y
            });
            return x
        });



        if (parentItem.categoryName == Constant.AppText.SideMenu_Title_StockRankings) {
            let index1 = CONTENT[index].subCategory.indexOf(subItem)
            CONTENT[index].subCategory[index1].isSelected = !CONTENT[index].subCategory[index1].isSelected
            this.setState({ refesh: this.state.refesh + 1 })
            setTimeout(() => {
                this.getUserList(CONTENT[index].subCategory[index1])

            }, 0)

            let isFirstTime = MyStorage.getItem(Constant.ShareInstanceKey.kISFirstTypePayment)
            if (isFirstTime == 'Yes') {
                MyStorage.setItem(Constant.ShareInstanceKey.kISFirstTypePayment, '')
                this.props.props.navigation.navigate('stocksScreen')

            } else {

                if (this.props.arrStockData.length > 0) {
                    this.props.props.navigation.navigate('Stocks')
                } else {
                    // this.props.props.navigation.navigate('stocksScreen')
                    window.location.href = window.location.origin;

                }
            }


        } else if (parentItem.categoryName == Constant.AppText.SideMenu_Title_AccountManagement) {

            let index1 = CONTENT[index].subCategory.indexOf(subItem)
            if (index1 == 0) {
                CONTENT[index].subCategory[index1].isSelected = !CONTENT[index].subCategory[index1].isSelected
                this.setState({ refesh: this.state.refesh + 1 })
                // this.props.onClickAuthPage(true)
                this.props.props.navigation.navigate('authScreen')
            }
        } else if (parentItem.categoryName == Constant.AppText.SideMenu_Title_MyLists) {

            let index1 = CONTENT[index].subCategory.indexOf(subItem)
            CONTENT[index].subCategory[index1].isSelected = !CONTENT[index].subCategory[index1].isSelected
            this.setState({ refesh: this.state.refesh + 1 })
            this.getFavStockList(CONTENT[index].subCategory[index1])
            // this.props.props.navigation.navigate('Stocks')

            let isFirstTime = MyStorage.getItem(Constant.ShareInstanceKey.kISFirstTypePayment)
            if (isFirstTime == 'Yes') {
                MyStorage.setItem(Constant.ShareInstanceKey.kISFirstTypePayment, '')
                this.props.props.navigation.navigate('stocksScreen')

            } else {
                // this.props.props.navigation.navigate('Stocks')
                if (this.props.arrStockData.length > 0) {
                    this.props.props.navigation.navigate('Stocks')
                } else {
                    // this.props.props.navigation.navigate('stocksScreen')
                    window.location.href = window.location.origin;

                }
            }


        }

    }


    renderBody = (item, index) => {
        let jsx = item.subCategory.map((subItem, idx) => {
            return (
                <View key={idx} style={{ marginTop: fontSizeScale(5) }}>
                    <View style={styles.headerList}>
                        <View style={styles.subItemBodyView}>
                            <TouchableOpacity style={{ flex: 1 }} onPress={() => this.onClickSubmenues(subItem, index, item)}>
                                <Text key={'subItemText'} style={[styles.subItemText, subItem.isSelected ? { fontFamily: Constant.AppFonts.Roboto.Bold, fontWeight: Constant.AppFontWeight.BOLD } : {}]}><Text style={{ fontSize: Platform.OS == 'web' ? 8 : 13 }}>● </Text> {subItem.name}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {idx == (item.subCategory.length - 1) && item.isExpand && <View style={styles.seperator} />}
                </View>
            )
        });
        return jsx
    }


    renderLine = () => {
        return (
            <View style={{
                height: '100%',
                width: 5,
                backgroundColor: Colors.APP_COLOR1
            }}
            />
        )
    }

    onHomeLogoClick = () => {

        if (Platform.OS == 'web') {
            window.location.href = window.location.origin;
        }
    }

    renderIcon = (item) => {

        if (item.icon == 'bar-chart' || item.icon == 'line-chart' || item.icon == 'user' || item.icon == 'list-ul') {
            return <Icon style={{ width: 22, height: 22 }} name={item.icon} size={fontSizeScale(20)} color={Platform.OS == 'web' ? 'white' : (item.isExpand ? Colors.APP_COLOR1 : Colors.APP_DARK_GARY)} />
        }
        if (item.icon == 'menu-sharp' || item.icon == 'paper-plane-sharp') {
            return <Ionicons style={{ width: 22, height: 22 }} name={item.icon} size={fontSizeScale(20)} color={Platform.OS == 'web' ? 'white' : (item.isExpand ? Colors.APP_COLOR1 : Colors.APP_DARK_GARY)} />
        } else {

            return (
                <Image
                    source={item.imageIcon}
                    resizeMode={'contain'}
                    style={{
                        width: 22,
                        height: 22,
                        tintColor: Platform.OS == 'web' ? 'white' : (item.isExpand ? Colors.APP_COLOR1 : Colors.APP_DARK_GARY)
                    }} />
            )
        }

    }


    renderHeader = (item) => {

        const paddingStart = Platform.OS == 'web' ? 15 : fontSizeScale(item.isExpand ? 10 : 15)
        return (
            <View key={'itemContainer_' + item.id} style={styles.itemContainer}>
                <View key={'rowItemContainer' + item.id} style={styles.rowItemContainer}>
                    {item.isExpand && Platform.OS != 'web' && this.renderLine()}
                    <View key={'rowItemView' + item.id} style={[styles.rowItemView, { paddingStart: paddingStart }]}>
                        <View key={'leftIconView' + item.id} style={styles.leftIconView}>
                            {this.renderIcon(item)}
                        </View>
                        <Text key={'itemText' + item.id} style={[styles.itemText, { color: Platform.OS == 'web' ? 'white' : (item.isExpand ? Colors.APP_COLOR1 : Colors.MAIN_TITLE_COLOR) }]}>{item.categoryName}</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Icon name={item.isExpand ? 'chevron-down' : 'chevron-right'} size={fontSizeScale(10)} color={Platform.OS == 'web' ? 'white' : (item.isExpand ? Colors.APP_COLOR1 : Colors.GRAY)} />
                        </View>
                    </View>
                </View>
                {!item.isExpand && <View style={styles.seperator} />}
            </View>
        )
    }


    getMenuOptionArray = () => {

        let status = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)
        CONTENT = []
        CONTENT.push(SideMenuOptionList.StockRankings)
        if (parseInt(status) == 1 && this.props.favList.length > 0) {
            CONTENT.push(SideMenuOptionList.MyLists)
        }
        CONTENT.push(SideMenuOptionList.AboutBestStocksNow)
        CONTENT.push(SideMenuOptionList.BSNShowsAndMore)
        CONTENT.push(SideMenuOptionList.AccountManagement)

        if (this.props.selectedSideMenuIndex >= 0) {

            CONTENT.map(function (x) {
                x.isExpand = false;
                return x
            });
            CONTENT[this.props.selectedSideMenuIndex].isExpand = true

            // console.log('this.props.selectedSideMenuSubIndex', this.props.selectedSideMenuSubIndex);
            if (this.props.selectedSideMenuSubIndex >= 0) {

                CONTENT.map(function (x) {
                    x.subCategory.map(function (y) {
                        y.isSelected = false;
                        return y
                    });
                    return x
                });

                CONTENT[this.props.selectedSideMenuIndex].subCategory[this.props.selectedSideMenuSubIndex].isSelected = true
            }

            this.onChangeExpand(CONTENT[this.props.selectedSideMenuIndex])
            this.props.changeSideMenuIndex(-1)
        }

        return CONTENT
    }

    renderItem = () => {

        let jsx = this.getMenuOptionArray().map((item, index) => {
            return (
                <Collapse key={index} disabled={item.isExpand} isExpanded={item.isExpand}
                    onToggle={(isCollapsed) => this.onChangeExpand(item)}>
                    <CollapseHeader>
                        {this.renderHeader(item)}
                    </CollapseHeader>
                    <CollapseBody>
                        {this.renderBody(item, index)}
                    </CollapseBody>
                </Collapse>
            )
        });

        return jsx

    }

    render() {

        const imageUrl = require('../assets/logo.png'); // Platform.OS == 'web' ? require('../assets/logo.png') : require('../assets/mobileSideMenu/mobileSideMenu.png');

        return (
            <View key={'mainContainer'} style={styles.mainContainer}>
                <DrawerContentScrollView contentContainerStyle={{ paddingTop: 0 }}>
                    <TouchableOpacity onPress={() => this.onHomeLogoClick()} key={'logoContainer'} style={styles.logoContainer}>
                        <Image
                            style={{ width: fontSizeScale(130), height: fontSizeScale(100), }}
                            source={imageUrl}
                        />
                    </TouchableOpacity>
                    <View style={styles.seperator} />

                    {this.renderItem()}
                </DrawerContentScrollView>
            </View >
        );
    }
}


const mapStateToProps = (state) => {

    return {
        arrStockData: state.stocksReducers.stockData,
        stocksModel: state.stocksReducers.stocksModelData,
        favList: state.stockOperationReducers.favList,
        selectedSideMenuIndex: state.stocksReducers.selectedSideMenuIndex,
        selectedSideMenuSubIndex: state.stocksReducers.selectedSideMenuSubIndex,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStockList: (sec, subSec) => dispatch(getStockList(sec, subSec)),
        saveNewSection: (sactionName) => dispatch(saveSection(sactionName)),
        saveNewSubSection: (subName) => dispatch(saveSubSection(subName)),
        addStockData: (filterData) => dispatch(addStockData(filterData)),
        setLoaderState: (isState) => dispatch(setLoaderState(isState)),
        getFavStocksList: (req) => dispatch(getFavStocksList(req)),
        createFavList: (request) => dispatch(createFavList(request)),
        changeSideMenuIndex: (index) => dispatch(changeSideMenuIndex(index)),
        onClickAuthPage: (status) => dispatch(onClickAuthPage(status)),



    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpendableDrawer);


const styles = StyleSheet.create({


    mainContainer: {
        flex: 1,
        backgroundColor: Platform.OS == 'web' ? Colors.APP_COLOR1 : 'white'
    },

    logoContainer: {
        paddingTop: fontSizeScale(20),
        height: fontSizeScale(160),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.APP_COLOR1 // Platform.OS == 'web' ? Colors.APP_COLOR1 : 'white'
    },

    itemContainer: {
        height: fontSizeScale(50),
    },

    rowItemContainer: {
        flexDirection: 'row',
        flex: 1,

    },

    rowItemView: {

        flexDirection: 'row',
        flex: 1,
        paddingStart: fontSizeScale(15),
        paddingEnd: fontSizeScale(10),
        backgroundColor: Platform.OS == 'web' ? Colors.APP_COLOR1 : 'white',
        height: fontSizeScale(50),
        alignItems: 'center',
        marginBottom: fontSizeScale(2),

    },

    leftIconView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10
    },

    itemText: {
        // color: 'white',
        color: Platform.OS == 'web' ? 'white' : Colors.MAIN_TITLE_COLOR,
        fontSize: fontSizeScale(13),
        flexGrow: 1,
        fontWeight: Constant.AppFontWeight.SEMI_BOLD,
        fontFamily: Constant.AppFonts.Roboto.Medium
    },

    subItemBodyView: {
        flexDirection: 'row',
        flex: 1,
        paddingStart: fontSizeScale(0),
        paddingEnd: fontSizeScale(10),
        // backgroundColor: Colors.APP_COLOR1,
        backgroundColor: Platform.OS == 'web' ? Colors.APP_COLOR1 : 'white',
        height: fontSizeScale(50),
        alignItems: 'center',
        marginBottom: fontSizeScale(2),

    },

    subItemText: {
        fontSize: fontSizeScale(12),
        flexGrow: 1,
        color: Platform.OS == 'web' ? 'white' : Colors.MAIN_TITLE_COLOR,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL

    },

    seperator: {
        // backgroundColor: ,
        backgroundColor: Platform.OS == 'web' ? 'rgba(255,255,255,0.1)' : Colors.APP_COLOR_BORDER,
        height: fontSizeScale(1)
    },

    headerList: {
        height: fontSizeScale(30),
        marginLeft: fontSizeScale(20),
    },

});
