import {
    DASHBOARD_DATA,
    STOCK_DATA,
    SECTION,
    SUB_SECTION,
    API_LOADER,
    SEARCH_KEY,
    STOCKS_DATA,
    PAGE_COUNT,
    STORE_FAVOURITE,
    ADD_FAVOURITE,
    ADD_FAV_STOCKS,
    UPDATE_STOCK_DATA,
    DRAWER_TOGGLE,
    FAVOURITE_HISTORY,
    SUBSCRIPTION_HISTORY,
    CLEAR_ALL_DATA,
    CRON_JOB_TIME,
    SECTOR_FILTER,
    MOMENTUM_FILTER,
    OPEN_DRAWER_TOGGLE,
    UPDATE_STOCK_MODEL_DATA,
    SELECTED_SMALL_SIDE_MENU,
    IS_AUTH_ACTION

} from './types.js'
import ApiManager from '../../Networking/ApiManager';
import { findUniqueValue, getEndPont } from '../../../Services/AppManager';
import Constant from '../../../Utils/Constant';
import StocksModel from '../../../Models/StocksModel';
import FavouriteListModel from '../../../Models/FavouriteListModel';
import MyStorage from '../../../Services/MyStorage';
import DynamicFilterOption from '../../../Models/DynamicFilterOption';

let apiManager = ApiManager.getInstance()
//  Project

export function getStockList(section, subSection) {

}

export function getAllStockList(dict) {

    return (dispatch) => {

        dispatch(isApiLoader(true))
        return new Promise((resolve, reject) => {

            // console.log('PARAM', dict);
            apiManager.onGetApi(Constant.EndPoint.getAllStocks, dict).then((responce) => {

                var stockDataTmp = []
                responce.json.data.map((data) => {
                    let model = new StocksModel()
                    stockDataTmp.push(model.init(data))
                });
                dispatch(isApiLoader(false))
                // dispatch(onClickAuthPage(true))

                if (responce.json.cron_job_time != undefined)
                    dispatch(setCronJobTime(responce.json.cron_job_time))

                let sectorOptions = findUniqueValue(stockDataTmp, 'sector')
                let momentomOptions = findUniqueValue(stockDataTmp, 'momentomGrd')
                // console.log('sectorOptions', sectorOptions);

                let dynamicOption = DynamicFilterOption.getInstance()
                dynamicOption.setSectionOptions(sectorOptions)
                dynamicOption.setMomentumOptions(momentomOptions)

                resolve(dispatch(saveAllStocks(stockDataTmp)))

            }).catch((error) => {
                // console.log('Error', error);
                reject(dispatch(isApiLoader(false)))
            })
        });

    }
}

export function getFavList(request) {

    return (dispatch) => {
        apiManager.onGetApi(Constant.EndPoint.getFavList, request).then((responce) => {

            if (responce.json.data.length > 0) {

                var stockDataTmp = []
                responce.json.data.map((data) => {
                    let model = new FavouriteListModel()
                    stockDataTmp.push(model.init(data))
                });
                dispatch(storeFavList(stockDataTmp))

            }

        }).catch((error) => {
            console.log(error.msg);
        })
    }
}

export function getFavStocksList(request, isLoader = true) {

    return (dispatch) => {

        dispatch(isApiLoader(isLoader))
        apiManager.onGetApi(Constant.EndPoint.getStockInList, request).then((responce) => {

            var stockDataTmp = []
            responce.json.data.map((data) => {
                let model = new StocksModel()
                stockDataTmp.push(model.init(data))
            });

            dispatch(addFavStockList(stockDataTmp))
            dispatch(isApiLoader(false))

            dispatch(addStockData(stockDataTmp))

        }).catch((error) => {
            console.log(error.msg);
            dispatch(isApiLoader(false))

        })
    }
}

export function createFavList(request) {
    return (dispatch) => {

        return new Promise((resolve, reject) => {
            apiManager.onPostApi(Constant.EndPoint.createFavList, request).then((responce) => {
                if (responce.json.status == 200) {
                    let modelObj = new FavouriteListModel()
                    dispatch(addFavList(modelObj.init(responce.json.data)))
                }
                dispatch(isApiLoader(false))
                resolve(responce)

            }).catch((error) => {
                dispatch(isApiLoader(false))
                resolve(error)
                console.log(error.msg);
            })
        })
    }
}

export function addStockInFav(reguest) {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            apiManager.onPostApi(Constant.EndPoint.addStockInFav, reguest).then((responce) => {
                console.log(responce);
                if (responce.json.status == 200) {
                    resolve(responce)
                }
            }).catch((error) => {
                // console.log(error.msg);
                reject(error)
            })
        });

    }
}

export function getSubscriptionDetails() {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            var dict = {
                userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
            }

            apiManager.onPostApi(Constant.EndPoint.getSubsription, dict).then((responce) => {
                if (responce.json.status == 200) {
                    dispatch(addSubscriptionHistory(responce.json.data))
                }
                resolve(responce)
            }).catch((error) => {
                // console.log(error.msg);
                reject(error)
            })
        });

    }
}



export function addDashBoardData(responce) {
    return {
        type: DASHBOARD_DATA,
        payload: responce
    }
}

export function addStockData(responce) {
    return {
        type: STOCK_DATA,
        payload: responce
    }
}

export function saveSection(sectionName) {
    return {
        type: SECTION,
        payload: sectionName
    }
}

export function saveSubSection(subSectionName) {
    return {
        type: SUB_SECTION,
        payload: subSectionName
    }
}


export function isApiLoader(isShowing) {
    console.log('isApiLoader', isShowing);
    return {
        type: API_LOADER,
        payload: isShowing
    }
}

export function setSearchedKey(searchText) {
    return {
        type: SEARCH_KEY,
        payload: searchText
    }
}

export function saveAllStocks(stocksModel) {
    return {
        type: STOCKS_DATA,
        payload: stocksModel
    }
}

export function updatePageCount(pageCount) {
    return {
        type: PAGE_COUNT,
        payload: pageCount
    }
}

export function storeFavList(responce) {
    return {
        type: STORE_FAVOURITE,
        payload: responce
    }
}

export function addFavList(responce) {
    return {
        type: ADD_FAVOURITE,
        payload: responce
    }
}

export function addFavStockList(responce) {
    return {
        type: ADD_FAV_STOCKS,
        payload: responce
    }
}

export function updateStockData(favData, actionType, index) {
    return {
        type: UPDATE_STOCK_DATA,
        actionType: actionType,
        actionIndex: index,
        payload: favData
    }
}

export function updateStockModelData(favData, actionType, index) {
    return {
        type: UPDATE_STOCK_MODEL_DATA,
        actionType: actionType,
        actionIndex: index,
        payload: favData
    }
}

export function changeDrawerToggle() {
    return {
        type: DRAWER_TOGGLE,
    }
}

export function openDrawer() {
    return {
        type: OPEN_DRAWER_TOGGLE,
    }
}


export function favStockDetails(responce) {
    return {
        type: FAVOURITE_HISTORY,
        payload: responce
    }
}

export function addSubscriptionHistory(responce) {
    return {
        type: SUBSCRIPTION_HISTORY,
        payload: responce
    }
}

export function clearDataONLogout() {
    return {
        type: CLEAR_ALL_DATA,
    }
}

export function setCronJobTime(responce) {
    return {
        type: CRON_JOB_TIME,
        payload: responce
    }
}

export function changeSideMenuIndex(responce, isSubtypeIndex = -1) {

    return {
        type: SELECTED_SMALL_SIDE_MENU,
        payload: responce,
        sideMenuSubIndex: isSubtypeIndex
    }
}

export function onClickAuthPage(responce) {

    // return {
    //     type: IS_AUTH_ACTION,
    //     payload: responce,
    // }
}



