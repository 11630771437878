import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import Constant from '../../../../Utils/Constant';
import MyStorage from '../../../../Services/MyStorage';
import * as Colors from '../../../../Utils/Colors';
import Loader from '../../common/LoaderComponent/Loader.web';
import { connect } from 'react-redux';
import { getSubscriptionDetails } from '../../../../Services/Redux/actions/stocksAction';
import moment from 'moment';
import { isSubscriptionTierLow, subscriptionEndDateLocal } from '../../../../Services/AppManager';
import Dialog from '@material-ui/core/Dialog';

import PaypalPopup from './PaypalPopup';

import ApiManager from '../../../../Services/Networking/ApiManager';

let apiManager = ApiManager.getInstance()


class SubscriptionView extends React.Component {

    selectedBtn = {
        headerTitle: 'Premium',
        headerSubTitle: 'Subscription',
        amount: Constant.SubscriptionPrice.PREMIUM,
        description: Constant.AppText.SubscriptionBox_2,
        subscriptionType: Constant.SubscriptionPlan.PREMIUM_PLAN_WEB,
        subscriptionID: Constant.PayPalKey.PREMIUM
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            openCreateListPopup: false,
            isShowPaypalPopup: false

        }
    }

    componentDidMount() {
        this.onMount()

    }

    onMount = () => {
        this.setState({ isLoader: true })

        this.props.getSubscriptionDetails().then((responce) => {

            if (responce.json.status == 200) {
                MyStorage.setItem(Constant.ShareInstanceKey.kUserID, responce.json.data[0]['userId'])
                MyStorage.setItem(Constant.ShareInstanceKey.kEmailId, responce.json.data[0]['email'])
                MyStorage.setItem(Constant.ShareInstanceKey.kPaymentStatus, responce.json.data[0]['status'])

                let endDateLocal = subscriptionEndDateLocal(responce.json.data[0]['subscriptionEndDate'])
                MyStorage.setItem(Constant.ShareInstanceKey.kSubscriptionEndDate, endDateLocal)

            }
            this.setState({ isLoader: false })
        }).catch((error) => {

            console.log('ERROR', error);
            this.setState({ isLoader: false })
            // console.log(error);
        })
    }

    changeEmail = () => {
        this.props.props.navigation.navigate('ChangeEmail')
    }

    onRepayment = () => {
        this.props.props.navigation.navigate('RePaymentContainer')
    }

    changePassword = () => {
        this.props.props.navigation.navigate('ChangePassword')
    }

    onLogout = () => {
        let status = confirm(Constant.AppStringMessages.logoutConfirmationMessage)
        if (status) {
            MyStorage.clear()
            window.location.href = window.location.origin;;
        }

    }

    getPlanName = () => {
        if (this.props.subscriptionHistory.length == 0)
            return ''
        if (this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_ANDROID || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_PLAN_WEB || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_PLAN_MANUALLY) {
            return `BSN Subscription ($${Constant.SubscriptionPrice.NORMAL})`
        }
        return `BSN Premium Subscription ($${Constant.SubscriptionPrice.PREMIUM})`
    }

    isPlanManually = () => {

        if (this.props.subscriptionHistory.length == 0) {
            return false
        } else if (this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_PLAN_MANUALLY || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.PREMIUM_PLAN_MANUALLY) {
            return true
        }
        return false

    }

    getBenefit = () => {
        if (this.props.subscriptionHistory.length == 0)
            return ''
        if (this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_ANDROID || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_PLAN_WEB || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_PLAN_MANUALLY) {
            return Constant.AppText.SubscriptionBox_Info
        }
        return Constant.AppText.SubscriptionBox_2
    }

    getTextColor = () => {

        if (this.checkSubscriptionStatus() == 0) {
            return {
                color: 'red',
            }
        } else {
            return {
                color: Colors.APP_DARK_GARY,
            }
        }
    }

    checkSubscriptionStatus = () => {

        if (this.props.subscriptionHistory.length == 0)
            return ''
        let status = this.props.subscriptionHistory[0]['status']
        return parseInt(status)
    }


    getExpInfo = () => {

        if (this.props.subscriptionHistory.length == 0)
            return ''

        let date = this.props.subscriptionHistory[0]['subscriptionEndDate']

        let localDatetime = moment(date + '+00:00').local().format('MM-DD-YYYY hh:mm a');

        // Your Monthly Subscription will be auto renew on 15 May 2021

        if (this.checkSubscriptionStatus() == 0) {
            return `Your subscription has been expired on ${localDatetime}`
        }

        if (this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.NORMAL_PLAN_MANUALLY || this.props.subscriptionHistory[0]['productId'] == Constant.SubscriptionPlan.PREMIUM_PLAN_MANUALLY) {
            return `Your subscription is valid till ${localDatetime}`
        }
        return `Your Monthly Subscription will be auto renew on ${localDatetime}`
    }

    upgradeSubscription = () => {
        this.setState({
            openCreateListPopup: true
        })

    }

    onNeedHelp = () => {

        this.setState({
            openCreateListPopup: false
        }, () => {
            this.props.props.navigation.navigate('SubscriptionHelp')

        });
    }

    onClosePopup = () => {
        this.setState({
            isShowPaypalPopup: false
        })
    }

    onPurchase = () => {

        this.setState({
            openCreateListPopup: false,
        }, () => {

            this.setState({

                isShowPaypalPopup: true
            })
        });

    }

    storePaypalIDServer = (paypalID, email) => {

        this.setState({
            isShowPaypalPopup: false,
            isLoader: true,
        })

        var dict = {
            'transaction_id': paypalID,
            'devEnvironment': Constant.AppEnvironments.release,
            'appVersion': Constant.AppInfo.version,
            'product_id': Constant.SubscriptionPlan.PREMIUM,
            'email': email,
        }

        if (MyStorage.getItem(Constant.ShareInstanceKey.kUserID) != '') {
            dict['userId'] = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
        }

        apiManager.onPostApi(Constant.EndPoint.verifyWebPayment, dict).then((responce) => {

            this.setState({
                isLoader: false
            })

            // console.log('responce', responce.json);
            if (responce.json.status == 200 || responce.json.status == 200) {

                // console.log('Ayaya', responce.json);

                MyStorage.setItem(Constant.ShareInstanceKey.kUserID, responce.json.data.userId)
                MyStorage.setItem(Constant.ShareInstanceKey.kEmailId, responce.json.data.email)
                MyStorage.setItem(Constant.ShareInstanceKey.kPaymentStatus, responce.json.data.status)
                MyStorage.setItem(Constant.ShareInstanceKey.kAuthToken, responce.json.data.token)
                MyStorage.setItem(Constant.ShareInstanceKey.kFetchAllStock, '1')

                // window.location.href = window.location.origin;
                this.onMount()

            } else {
                alert(Constant.ErrorMessage.ServerError)
            }
            // console.log('Finally', responce.json);

        }).catch((error) => {
            console.log('eoor');

            console.log(error.msg);
        })

    }

    isSubscriptionTierLowLocal = () => {
        if (this.props.subscriptionHistory.length == 0)
            return false
        return isSubscriptionTierLow(this.props.subscriptionHistory[0]['productId'])
    }




    render() {

        let email = MyStorage.getItem(Constant.ShareInstanceKey.kEmailId)
        let status = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)


        const handleClose = () => {
            this.setState({
                openCreateListPopup: false
            })
        };

        return (

            <View key={'mainContainer'} style={styles.mainContainer}>
                <View key={'centerContainer'} style={styles.centerContainer}>
                    <Text style={styles.emialTextStyle}>Hello, {email}</Text>
                    <Text style={[styles.textStyle, this.getTextColor()]}>{this.getExpInfo()}</Text>

                    <Text style={styles.textBoldStyle}>Plan Name: </Text>
                    <Text style={styles.textStyle}>{this.getPlanName()}</Text>
                    <View key={'separatorView_1'} style={styles.separatorView} />
                    {!this.isPlanManually() &&
                        <>
                            <Text style={styles.textBoldStyle}>Duration:</Text>
                            <Text style={styles.textStyle}>1 Month/Auto-renewal until you cancel</Text>
                            <View key={'separatorView_2'} style={styles.separatorView} />
                        </>
                    }

                    <Text style={styles.textBoldStyle}>Benefits:</Text>
                    <Text style={styles.textStyle}>{this.getBenefit()}</Text>

                    <View key={'butttonView'} style={styles.butttonView}>

                        <TouchableOpacity onPress={() => this.changeEmail()}>
                            <Text style={styles.buttonText}>Change Email</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => this.changePassword()}>
                            <Text style={styles.buttonText}>Change Password</Text>
                        </TouchableOpacity>

                        {parseInt(status) == 1 && this.isSubscriptionTierLowLocal() &&
                            <TouchableOpacity onPress={() => this.upgradeSubscription()}>
                                <Text style={styles.buttonText}>Upgrade</Text>
                            </TouchableOpacity>
                        }

                        {parseInt(status) == 1 &&

                            <TouchableOpacity onPress={() => this.onLogout()}>
                                <Text style={styles.buttonText}>Logout</Text>
                            </TouchableOpacity>
                        }
                    </View>

                    {parseInt(status) == 0 &&

                        <View key={'butttonView_2'} style={[styles.butttonView, { marginTop: 10 }]}>

                            <TouchableOpacity onPress={() => this.onRepayment()}>
                                <Text style={styles.buttonText}>Pay Again</Text>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => this.onLogout()}>
                                <Text style={styles.buttonText}>Logout</Text>
                            </TouchableOpacity>

                        </View>

                    }

                </View>

                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={this.state.openCreateListPopup}>

                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.Container}>
                            <View style={styles.Body}>
                                <Text style={styles.Body_Text}>
                                    You have an active subscription, please remember cancelling it to avoid double charges.
                                </Text>

                            </View>
                            <View style={styles.Container_Button}>

                                <TouchableOpacity onPress={() => this.onPurchase()} style={styles.Button_2} >
                                    <Text style={styles.ButtonText_2}>Purchase</Text>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => this.onNeedHelp()} style={styles.Button_2} >
                                    <Text style={styles.ButtonText_2}>Need Help</Text>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={handleClose} style={styles.Button_1}>
                                    <Text style={styles.ButtonText_1}>CANCEL</Text>
                                </TouchableOpacity>

                            </View>
                        </View>
                    </View>
                </Dialog>

                {
                    this.state.isShowPaypalPopup &&
                    <PaypalPopup btnInfo={this.selectedBtn}
                        onClosePopup={this.onClosePopup.bind()}
                        onSuccess={this.storePaypalIDServer.bind()}
                    />
                }

                {this.state.isLoader && <Loader />}
            </View >
        )

    }
}


const mapStateToProps = (state) => {
    return {
        subscriptionHistory: state.stockOperationReducers.subscriptionHistory
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSubscriptionDetails: () => dispatch(getSubscriptionDetails()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionView);


const styles = StyleSheet.create({

    ButtonText_1: {

        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },

    Container_Button: {
        // backgroundColor: '#007AFF',
        alignItems: 'center',
        // flexDirection: 'row',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },

    Container: {
        width: 280,
        height: 250,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        borderRadius: 10,

    },
    Body: {
        paddingHorizontal: 20,
        paddingVertical: 20
    },
    Body_Text: {
        fontSize: 14,
        paddingTop: 15,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL,
    },

    mainContainer: {
        flex: 1,
        alignItems: 'center',
    },

    centerContainer: {
        width: 450,
        padding: 30,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3,
        borderRadius: 10,
        backgroundColor: 'white'
    },

    emialTextStyle: {
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontSize: 14,
        color: Colors.MAIN_TITLE_COLOR,
        marginTop: 5,
        fontWeight: Constant.AppFontWeight.BOLD,
    },


    textStyle: {
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        fontSize: 12,
        color: Colors.APP_DARK_GARY,
        marginTop: 5,
        lineHeight: 20
    },

    textBoldStyle: {
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontSize: 14,
        color: Colors.APP_COLOR1,
        marginTop: 20,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    separatorView: {
        height: 2,
        backgroundColor: Colors.APP_COLOR_BORDER,
        marginTop: 6
    },

    butttonView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 30,
        flexWrap: 'wrap'
    },

    buttonText: {
        fontSize: 12,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.SEMI_BOLD,
        color: Colors.APP_COLOR1,
        textDecorationLine: 'underline'
    },
    Button_1: {
        // backgroundColor: '#DCDCDC',
        width: '33%',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomLeftRadius: 5,

    },
    Button_2: {
        // backgroundColor: '#007AFF',
        width: '33%',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomRightRadius: 5,


    },
    ButtonText_1: {

        color: Colors.APP_COLOR1,
        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },
    ButtonText_2: {
        // color: 'white',
        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    }


})

