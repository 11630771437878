
import * as React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import * as Colors from '../../../Utils/Colors';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import SubscriptionManager from '../../Components/WebComponent/AccountManager/SubscriptionManager';
import MyStorage from '../../../Services/MyStorage';


class RePaymentContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    realodPage = () => {

        this.props.navigation.reset({
            index: 0,
            routes: [{ name: 'authScreen' }],
        });

    }


    render() {

        return (

            <View key={'headerContainer'} style={styles.headerContainer}>
                <HeaderWeb props={this.props} pageTitleArray={['Manage Subscription']} />
                <ScrollView key={'mainContainer'} style={styles.mainContainer}>
                    <SubscriptionManager props={this.props} onRefresh={this.realodPage.bind()} />
                </ScrollView>
            </View>

        );
    }
}

export default RePaymentContainer;


const styles = StyleSheet.create({

    headerContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
    },

    mainContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
        padding: 30,
        paddingTop: 30,
    },

});
