

import Constant from '../Utils/Constant';
import FavouriteStocksListModel from './FavouriteStocksListModel';

export default class StocksModel {

    constructor(
        sym = '',
        stkName = '',
        gundersonGrade = '',
        lastClosingPrice = 0.0,
        marketSize = '',
        marketCap = '',
        momentom = 0.0,
        momentomGrd = '',
        ranking = 0,
        sector = '',
        riskProfile = '',
        favouritesStockList = [],
        etf = "0"

    ) {
        this.sym = sym
        this.stkName = stkName
        this.gundersonGrade = gundersonGrade
        this.lastClosingPrice = lastClosingPrice
        this.marketSize = marketSize
        this.marketCap = marketCap
        this.momentom = momentom
        this.momentomGrd = momentomGrd
        this.ranking = ranking
        this.sector = sector
        this.riskProfile = riskProfile
        this.favouritesStockList = favouritesStockList
        this.etf = etf

    }

    init(stock) {

        this.sym = stock.sym
        this.stkName = stock.stk_name

        if (parseFloat(stock.prc_1yr_ago) > 0.0) {
            this.gundersonGrade = stock.gcm_grd
        } else {
            this.gundersonGrade = 'N/A'
        }

        this.lastClosingPrice = stock.lastClosingPrice != null ? parseFloat(stock.lastClosingPrice) : 0.0
        this.marketSize = stock.marketSize
        this.marketCap = stock.marketCap
        this.momentom = stock.momentom != null ? parseFloat(stock.momentom) : 0.0
        // this.momentomGrd = stock.momentomGrd

        if (parseFloat(stock.prc_1yr_ago) > 0.0) {
            this.momentomGrd = stock.momentomGrd
        } else {
            this.momentomGrd = 'N/A'
        }


        this.ranking = (stock.ranking == null || stock.ranking == 0 || stock.ranking == undefined) ? Constant.RankingValue.rank : parseInt(stock.ranking)
        this.sector = stock.sector_name
        this.riskProfile = stock.riskProfile
        this.favouritesStockList = []
        this.etf = stock.etf != null ? String(stock.etf) : "0"

        if (stock.favourites_stock_list != undefined) {
            stock.favourites_stock_list.forEach(favlList => {

                let model = new FavouriteStocksListModel()
                this.favouritesStockList.push(model.init(favlList))

            });

        }

        return this

    }
}