
import * as React from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import { saveSubSection, addStockData, isApiLoader } from '../../../Services/Redux/actions/stocksAction';
import { getFilterOption, filterArrayByOptions } from '../../../Services/AppManager';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
import Constant from '../../../Utils/Constant';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { CheckBox } from 'react-native-elements';
import * as Colors from '../../../Utils/Colors';


class FIlterOption extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isPopupShowing: false
        }

    }

    onSelected = (selected) => {

        let arrStocksData = this.props.arrAllStockData;
        let filteredData = filterArrayByOptions(arrStocksData, selected)
        this.props.saveNewSubSection(selected)
        this.props.addStockData(filteredData)

    }


    renderOptions = () => {

        let optionList = getFilterOption(this.props.selectedSection)
        let jsx = optionList.map((item, index) => {


            let isRowSelected = item.label == this.props.selectedSubSection.label ? true : false

            return (
                <TouchableOpacity key={index} onPress={() => this.onSelected(item)} style={{ flexDirection: 'row', padding: 5, backgroundColor: isRowSelected ? Colors.SELECTED_VIEW_BG : 'white' }}>
                    <CheckBox
                        title={item.label}
                        checked={isRowSelected}
                        containerStyle={{ backgroundColor: isRowSelected ? '#e9f4fe' : 'white', borderWidth: 0, marginBottom: 5, padding: 0, }}
                        textStyle={{ color: isRowSelected ? Colors.APP_COLOR1 : Colors.APP_DARK_GARY, fontSize: 12, fontWeight: Constant.AppFontWeight.MEDIUM }}
                        fontFamily={Constant.AppFonts.Roboto.Regular}
                        size={20}
                        checkedIcon={'dot-circle-o'}
                        uncheckedIcon={'circle-o'}
                        checkedColor={Colors.APP_COLOR1}
                        onPress={() => this.onSelected(item)}
                    />
                </TouchableOpacity>

            )
        })

        return jsx

    }

    render() {


        const PopoverStyle = {
            height: '240px',
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            border: '0px',

        };

        const button = {
            backgroundColor: 'white',
            border: '1px',
        }


        return (
            <View style={{}}>

                {this.props.selectedSubSection.label != 'Hide' &&

                    <OverlayTrigger
                        trigger='focus'
                        key={'bottom'}
                        placement={'bottom'}
                        delay={200}
                        onToggle={(nextShow) => this.setState({ isPopupShowing: nextShow })}
                        overlay={
                            <Popover style={PopoverStyle} id={`popover_class`}>
                                <Popover.Content style={{ height: '240px', flex: 1, padding: 0 }}>
                                    <ScrollView indicatorStyle={'red'} style={{ height: 230 }}>
                                        {this.renderOptions()}
                                    </ScrollView>
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <TouchableOpacity
                            // variant="outline-primary"
                            style={{
                                color: "#b4b4bc",
                                background: "white",
                                // border: '2px solid #EFEFEF',
                                borderRadius: '5px',
                                borderColor: this.state.isPopupShowing ? Colors.APP_COLOR1 : '#EFEFEF',
                                borderWidth: 2,
                                fontSize: 14,
                                fontFamily: Constant.AppFonts.Roboto.Medium,
                                fontWeight: Constant.AppFontWeight.MEDIUM,
                                height: 45,
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 5
                            }}
                        >
                            <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                height: 25,

                            }}>
                                <Text style={{
                                    color: this.state.isPopupShowing ? Colors.APP_COLOR1 : Colors.MAIN_TITLE_COLOR,
                                    fontSize: 14,
                                    fontFamily: Constant.AppFonts.Roboto.Medium,
                                    fontWeight: Constant.AppFontWeight.MEDIUM,
                                }} >{this.props.selectedSubSection.label || 'Loading...'}</Text>
                                <MaterialIcons style={{ marginLeft: 20 }} name={'keyboard-arrow-down'} size={20} color={this.state.isPopupShowing ? Colors.APP_COLOR1 : Colors.APP_DARK_GARY} />
                            </View>
                        </TouchableOpacity>
                    </OverlayTrigger>
                }
            </View>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedSection: state.stocksReducers.selectedSection,
        selectedSubSection: state.stocksReducers.selectedSubSection,
        arrAllStockData: state.stocksReducers.stocksModelData,
        stockData: state.stocksReducers.stockData,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveNewSubSection: (subName) => dispatch(saveSubSection(subName)),
        addStockData: (filterData) => dispatch(addStockData(filterData)),
        isApiLoader: (loading) => dispatch(isApiLoader(loading))


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FIlterOption);

const styles = StyleSheet.create({

});